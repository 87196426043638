import offlineIcon from '../../assets/images/offline.svg';

export const OfflineThumbnail = () => {
  return (
    <img
      className={
        'absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] sm:w-[35px] ml:w-[35px] mp:w-[10vw]'
      }
      src={offlineIcon}
      alt="Stream offline"
    />
  );
};
