import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {useVod} from '../../context/VodContext';
import {useEffect, useState} from 'react';
import secondsToHMS from '../../util/secondsToHMS';

const Scrubber: React.FC<any> = () => {
  const {getEventEndTime, seekTime, getCurrentTime} = useVod();

  const value = getCurrentTime();
  const max = getEventEndTime();

  const [tempValue, setTempValue] = useState(value);

  useEffect(() => {
    let interval = setInterval(() => {
      setTempValue(value + 1);
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <div className="flex w-72 flex-wrap items-center justify-end">
      {secondsToHMS(tempValue) + ' ' + secondsToHMS(max)}
      <Slider
        min={0}
        max={max}
        defaultValue={value}
        value={tempValue}
        onChange={newValue => setTempValue(() => newValue as number)}
        onChangeComplete={newValue => {
          seekTime(newValue as number);
          setTempValue(newValue as number);
        }}
      />
    </div>
  );
};

export default Scrubber;
