import cx from 'classnames';
import {useCallback, useEffect, useState} from 'react';
import * as portals from 'react-reverse-portal';
import {ReactComponent as CameraIcon} from '../../assets/images/camera.svg';
import {ReactComponent as SelectedBg} from '../../assets/images/selected-perspective.svg';
import {useAudio} from '../../context/AudioContext';
import {useStreams} from '../../context/StreamsContext';
import {VodPerspective} from '../../context/types';
import utilStyles from '../../util/util.module.scss';
import {SoundButton} from '../Buttons/SoundButton/SoundButton';
import {MusicNotes} from '../MusicNotes/MusicNotes';
import {VodPerspectiveTitle} from '../VodPerspectiveTitle/VodPerspectiveTitle';
import styles from './VodPerspectiveTile.module.scss';

export interface VodPerspectiveTileProps {
  perspective: VodPerspective;
  selected?: boolean;
  hasNextPerspective: boolean;
  onClick: () => void;
}

export const VodPerspectiveTile: React.FC<VodPerspectiveTileProps> = ({
  perspective,
  selected,
  hasNextPerspective,
  onClick,
}) => {
  const {streams} = useStreams();
  const {audioSource, setAudioSource, sourceMuted, setSourceMuted} = useAudio();
  const [variant, setVariant] = useState(0);
  useEffect(() => setVariant(Math.floor(Math.random() * 4)), [selected]);

  const selectable = !selected;

  const handleSound = () => {
    if (audioSource !== perspective.id) {
      setAudioSource(perspective.id);
      setSourceMuted(false);
    } else {
      setSourceMuted(muted => !muted);
    }
  };

  const selectedThumbnail = (
    <>
      <SelectedBg
        className={cx(
          styles.bg,
          'absolute top-0 bottom-0 right-0 left-0 col-start-1 row-start-1',
        )}
      />
      <CameraIcon
        className={cx(
          styles.camera,
          'absolute top-[50%] left-[50%] col-start-1 row-start-1 translate-x-[-50%] translate-y-[-50%]',
        )}
      />
    </>
  );

  const onClickCallback = useCallback(() => {
    if (selectable) {
      onClick();
    }
  }, [selectable, onClick]);

  const [thumbnailNode, setThumbnailNode] =
    useState<portals.HtmlPortalNode | null>(null);

  const liveThumbnail = thumbnailNode && (
    <portals.OutPortal onClick={onClickCallback} node={thumbnailNode} />
  );

  useEffect(() => {
    if (selectable && streams.current[perspective.id]) {
      const s = streams.current[perspective.id];
      setThumbnailNode(s?.thumbnailify() ?? null);
      return () => s?.unthumbnailify();
    }
  }, [selectable, thumbnailNode, streams, perspective.id]);

  const thumbnail: JSX.Element | undefined = selected
    ? selectedThumbnail
    : liveThumbnail ?? <></>;

  return (
    <div
      className={cx('flex-none items-start justify-start mp:flex', 'mp:mb-4')}
    >
      <div
        onClick={onClickCallback}
        className={cx(
          'relative w-56 pb-[56.25%] ml:w-[22vw] mp:max-w-[50%] mp:pb-[28.125%]',
        )}
      >
        <div
          className={cx(
            'bg-[#01031a]',
            '!absolute top-0 bottom-0 right-0 left-0',
            'grid h-full w-full place-items-center rounded',
            {'active:ring': selectable},
            {'hover:brightness-150': selectable},
            selected && [styles.selected, styles[`selected${variant}`]],
            utilStyles.safariClip,
            !(selected && hasNextPerspective) && [styles.offline],
          )}
        >
          {thumbnail}
          {!sourceMuted && perspective.id === audioSource && <MusicNotes />}
        </div>
      </div>
      <div
        className={cx(
          'mt-2 flex h-10 flex-auto items-center justify-between mp:mt-0 mp:self-center',
        )}
      >
        <VodPerspectiveTitle perspectiveTitle={perspective.title} live={true} />
        <SoundButton
          handleSound={handleSound}
          sourceMuted={sourceMuted}
          audioSource={audioSource}
          perspectiveId={perspective.id}
        />
      </div>
    </div>
  );
};
