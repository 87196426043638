import logo from '../../assets/images/incrowd-white.svg';

export const Footer = () => {
  return (
    <footer className={'absolute bottom-7 right-4 ml:hidden mp:hidden'}>
      <a
        href="https://www.incrowd.live/"
        target="_blank"
        className="flex items-baseline gap-2"
        rel="noreferrer"
      >
        <div className="text-[3vw] sm:text-xs">POWERED BY</div>
        <img
          className="relative top-0.5 w-[25vw] sm:w-[90px]"
          src={logo}
          alt="incrowd"
          width="90"
        />
      </a>
    </footer>
  );
};
