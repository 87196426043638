import {FC, FunctionComponent, SVGProps, useState} from 'react';
import cx from 'classnames';

export interface SideBarItemProps {
  handleClick: () => void;
  Icon: FunctionComponent<
    SVGProps<SVGSVGElement> & {title?: string | undefined}
  >;
}

export const SideBarItem: FC<SideBarItemProps> = ({handleClick, Icon}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <button
      className={cx(
        'z-10 flex w-11 items-center gap-3 overflow-hidden p-3 transition-all duration-300 ease-linear',
        'hover:w-44 hover:brightness-150 hover:transition-all hover:duration-300 hover:ease-linear',
      )}
      onClick={handleClick}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <div>
        <Icon className="h-5 w-5" />
      </div>
      <span className={cx('whitespace-nowrap text-sm', !isHovered && 'hidden')}>
        Community Tab
      </span>
    </button>
  );
};
