import cx from 'classnames';
import {ReactComponent as VolumeIcon} from '../../assets/images/volume.svg';
import {VolumeIconContainerProps} from './types';

export const VolumeIconContainer: React.FC<VolumeIconContainerProps> = ({
  perspectiveTitle,
}) => {
  return (
    <>
      <VolumeIcon
        className={cx(
          'mr-2 hover:brightness-75 active:brightness-50 mp:h-5 mp:w-5',
        )}
      />
      <h3
        className={cx(
          'overflow-hidden text-ellipsis whitespace-nowrap text-ic-grey-400 mp:text-sm',
        )}
      >
        Listening to <span className="text-white">{`${perspectiveTitle}`}</span>
      </h3>
    </>
  );
};
