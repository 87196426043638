import {RetryLink} from '@apollo/client/link/retry';
import {AuthOptions, createAuthLink} from 'aws-appsync-auth-link';
import {cache} from '../cache';
import {createSubscriptionHandshakeLink} from 'aws-appsync-subscription-link';
import {ApolloClient, ApolloLink, createHttpLink} from '@apollo/client';

if (
  !(
    process.env.REACT_APP_API &&
    process.env.REACT_APP_AWS_REGION &&
    process.env.REACT_APP_API_KEY
  )
) {
  throw new Error('REACT_APP variables not set.');
}

const url = process.env.REACT_APP_API;
const apiKey = process.env.REACT_APP_API_KEY;
const region = process.env.REACT_APP_AWS_REGION;
const auth: AuthOptions = {
  type: 'API_KEY',
  apiKey,
};

const httpLink = createHttpLink({uri: process.env.REACT_APP_API});
const link = ApolloLink.from([
  new RetryLink(),
  createAuthLink({url, region, auth}),
  createSubscriptionHandshakeLink({url, region, auth}, httpLink),
]);

export const client = new ApolloClient({
  link,
  cache,
  connectToDevTools: process.env.NODE_ENV === 'development',
});

export default client;
