import {EventSponsorship} from './types';

export const SponsorHeader: React.FC<EventSponsorship> = ({title, logo}) => {
  return title && logo ? (
    <>
      <div className="mx-4 h-9 w-[1px] bg-gray-500"></div>
      <div className={`grow`}>
        <p className="mb-[-2px] text-xs text-gray-500">SPONSORED BY</p>
        <div className="flex items-center">
          <img
            className="mr-1 h-4 w-4 content-center rounded-full"
            src={logo}
            alt={'sponsor logo'}
          />
          <p className="text-base">{title}</p>
        </div>
      </div>
    </>
  ) : null;
};
