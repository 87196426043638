import {AddToCalendarButton} from 'add-to-calendar-button-react';
import {CalendarButtonProps} from './types';
import TagManager from 'react-gtm-module';

export const CalendarButton: React.FC<CalendarButtonProps> = ({
  title,
  startDate,
  startTime,
  endDate,
  endTime,
  timezone,
  eventLink,
}) => {
  return (
    <button
      onClick={() => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'addToCalendar',
          },
        });
      }}
      data-testid={title}
    >
      <AddToCalendarButton
        name={title}
        options={[
          'Apple',
          'Google',
          'iCal',
          'Microsoft365',
          'Outlook.com',
          'Yahoo',
        ]}
        location="incrowd"
        description={eventLink}
        startDate={startDate}
        endDate={endDate}
        startTime={startTime}
        endTime={endTime}
        timeZone={timezone}
        listStyle="dropdown"
        buttonStyle="round"
        hideBackground={true}
        hideCheckmark={true}
        lightMode="bodyScheme"
        size="3|4|5"
      ></AddToCalendarButton>
    </button>
  );
};
