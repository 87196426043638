import {Badge} from '../Badge/Badge';
import {TitleProps} from './types';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import cx from 'classnames';
import {useEffect, useState} from 'react';
import EmojiReaction from '../EmojiReaction/EmojiReaction';

export const Title: React.FC<TitleProps> = ({
  text,
  isLive,
  socket,
  isVod,
  startTime,
  currentPerspective,
}) => {
  const formattedStartTime = startTime?.local().format('MMM D, YYYY hh:mm A');
  const isDesktopView = useWindowDimensions().width > 640;
  const [viewers, setViewers] = useState('0');
  const [isToggled, setIsToggled] = useState(false);
  const viewerText = viewers !== '1' ? ' viewers' : ' viewer';

  useEffect(() => {
    socket &&
      socket.emit('check', currentPerspective?.id, (userCount: string) => {
        setViewers(userCount);
      });
  });

  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-col">
        <div className="flex items-center gap-2">
          <h1
            className={cx(
              'max-h-[60px] text-lg',
              !isDesktopView && 'overflow-hidden font-semibold',
            )}
          >
            {text}
          </h1>
          {isLive && isDesktopView && <Badge>Live</Badge>}
        </div>
        {isLive && !isDesktopView && (
          <div className="flex flex-row items-center gap-2">
            <p className="text-sm text-ic-grey-400">
              {viewers?.toLocaleString()} {viewerText}
            </p>
            <div className="h-1 w-1 rounded bg-live-red-400"></div>
            <p className="text-sm text-live-red-400">LIVE</p>
          </div>
        )}
        {isVod && formattedStartTime && (
          <p className="text-sm text-ic-grey-400">
            Premiered on {formattedStartTime}
          </p>
        )}
      </div>
      <div className="ml-2 flex items-end gap-2">
        {!isDesktopView && isLive && <div className="relative h-10 w-10" />}
        {!isDesktopView && isLive && (
          <div className="absolute right-3 flex w-10 flex-col items-center justify-end">
            <EmojiReaction
              socket={socket}
              perspectiveId={currentPerspective?.id}
              isToggled={isToggled}
              setIsToggled={setIsToggled}
            />
          </div>
        )}
      </div>
    </div>
  );
};
