import logo from '../../assets/images/incrowd-white.svg';
import {Feedback} from '../Feedback/Feedback';

export const MobileHeader = () => (
  <header className="flex h-fit justify-between p-4">
    <a
      href={process.env.REACT_APP_HOME}
      target="_blank"
      className="flex h-fit items-baseline gap-2"
      rel="noreferrer"
      data-testid="home-page-link"
    >
      <img
        className="relative top-0.5 w-[25vw] sm:w-[90px]"
        src={logo}
        alt="incrowd"
        width="90"
      />
    </a>
    <Feedback title="Send Feedback" />
  </header>
);
