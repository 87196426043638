function secondsToHMS(seconds: number) {
  const minutes = Math.floor((seconds % (60 * 60)) / 60);
  const hours = Math.floor(seconds / (60 * 60));
  const displaySeconds = Math.floor(seconds % 60);
  const hoursString = `${hours > 0 ? `${hours}:` : ''}`;
  return `${hoursString}${minutes < 10 ? '0' : ''}${minutes}:${
    displaySeconds < 10 ? '0' : ''
  }${displaySeconds}`;
}

export default secondsToHMS;
