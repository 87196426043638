import cx from 'classnames';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AudioContextProvider} from '../../context/AudioContext';
import {StreamsContextProvider} from '../../context/StreamsContext';
import {useVod} from '../../context/VodContext';
import {useElementDimensions} from '../../hooks/useElementDimensions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {PerspectiveState} from '../../types/Event';
import {calculateStreamStyle, isPortrait} from '../../util/responsive';
import utilStyles from '../../util/util.module.scss';
import {StreamControls} from '../StreamControls/StreamControls';
import {StreamEnded} from '../StreamEnded/StreamEnded';
import {Tab} from '../Tab/Tab';
import {VodPerspectiveRadio} from '../VodPerspectiveRadio/VodPerspectiveRadio';
import {VodStack} from '../VodStack/VodStack';
import styles from './VodViewer.module.scss';
import {VodViewerProps} from './types';

export const VodViewer: React.FC<VodViewerProps> = ({
  eventTitle,
  mainAudio,
  mainVideo,
  perspectives,
  toggleCommunityTab,
  isCommunityEnabled,
  startTime,
}) => {
  const [isFullScreenState, setIsFullScreenState] = useState(false);
  const blackBarsRef = useRef<HTMLDivElement>(null);
  const blackBarsDim = useElementDimensions(blackBarsRef);
  const {
    currentStreamDimensions,
    setStreamingDimensions,
    currentPlayingVideos,
  } = useVod();

  const [playing, setPlaying] = useState(true);
  const [showInitialMuteButton, setShowInitialMuteButton] = useState(true); //used to determine when to show "Tap to Unmute" button
  const [aspectRatio, setAspectRatio] = useState<number>(16 / 9);

  // Compute the aspect ratio of the available space around the video player.
  // If it is greater than 16:9, we must add black bars on the left and right.
  // Otherwise, we add bars on the top and bottom.  This can be replaced
  // entirely with CSS when they add container queries.
  const vertBlackBars =
    blackBarsDim && blackBarsDim.width / blackBarsDim.height > aspectRatio;

  const {width, height} = useWindowDimensions();

  const isSmallScreen = width <= 640 || height <= 640;

  const [selectedTab, setSelectedTab] = useState<string>('');
  const [currentPerspective, setCurrentPerspective] = useState<number>(0);

  const [selected, setSelected] = useState<string | null>(null);

  const live = useMemo(() => {
    return Object.values(perspectives).some(
      p => p.perspectiveState === PerspectiveState.Live,
    );
  }, [perspectives]);

  const handleSelectTab = useCallback(
    (tab: string) => {
      if (selectedTab !== tab) {
        setSelectedTab(tab);
        toggleCommunityTab();
      }
    },
    [selectedTab, toggleCommunityTab],
  );

  useEffect(() => {
    if (selected) {
      setStreamingDimensions(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlayingVideos]);

  useEffect(() => {
    if (selected) {
      setStreamingDimensions(selected);
      const currOrder = perspectives[selected].order;
      setCurrentPerspective(currOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, perspectives]);

  useEffect(() => {
    if (selected) {
      if (
        isPortrait(
          currentStreamDimensions?.videoHeight,
          currentStreamDimensions?.videoWidth,
        ) &&
        width > 640
      ) {
        setAspectRatio(9 / 16);
      } else {
        setAspectRatio(16 / 9);
      }
    }
  }, [selected, width, currentStreamDimensions]);

  return (
    <StreamsContextProvider>
      <AudioContextProvider>
        <div
          className={cx(
            'grid h-full max-w-full select-none ml:grid-rows-1 mp:block',
            styles.viewerGrid,
          )}
        >
          <div
            className={cx(
              'flex w-full grow flex-col items-center justify-center',
              'ml:m-auto',
              'mp:top-0 mp:left-0',
              'mp:!min-h-[auto]',
              'transition-all duration-300',
              'z-[2]',
              'relative',
            )}
            ref={blackBarsRef}
            style={{
              width: calculateStreamStyle({
                measurementType: 'width',
                isSmallScreen,
                width,
                height,
                aspectRatio,
                currentStreamDimensions,
              }),
              height: calculateStreamStyle({
                measurementType: 'height',
                isSmallScreen,
                width,
                height,
                aspectRatio,
                currentStreamDimensions,
              }),
            }}
          >
            <div
              className={cx(
                styles.VodViewer,
                vertBlackBars === null
                  ? 'w-0'
                  : vertBlackBars
                  ? `h-full`
                  : `w-full`,
                'bg-ic-blue-400',
                `${
                  currentStreamDimensions &&
                  isPortrait(
                    currentStreamDimensions.videoHeight,
                    currentStreamDimensions.videoWidth,
                  ) &&
                  selected
                    ? 'aspect-[9/16] ml:w-1/3 portrait:w-1/2'
                    : 'aspect-video overflow-hidden ml:w-full'
                }`,
                'rounded-lg ml:h-full ml:rounded-none mp:h-full',
                utilStyles.safariClip,
              )}
              style={{
                backgroundImage: '',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <VodStack
                live={live}
                eventTitle={eventTitle}
                perspectives={perspectives}
                mainAudio={mainAudio}
                mainVideo={mainVideo}
                selected={selected}
                setSelected={setSelected}
                setShowInitialMuteButton={setShowInitialMuteButton}
                playing={playing}
              />
              {!live && <StreamEnded eventEnded />}
            </div>
            {live && (
              <StreamControls
                setIsFullScreenState={setIsFullScreenState}
                isFullScreenState={isFullScreenState}
                perspectives={perspectives}
                selected={selected}
                setSelected={setSelected}
                playing={playing}
                onPause={() => setPlaying(false)}
                onPlay={() => setPlaying(true)}
                showInitialMuteButton={showInitialMuteButton}
                setShowInitialMuteButton={setShowInitialMuteButton}
                currentPerspective={currentPerspective}
                isVod={true}
              />
            )}
          </div>
          <Tab
            eventTitle={eventTitle}
            selectedTab={selectedTab}
            isCommunityEnabled={isCommunityEnabled}
            onClickOnPerspectivesTab={() => handleSelectTab('Perspectives')}
            onClickOnCommunityTab={() => handleSelectTab('Community')}
            isVod={true}
            startTime={startTime}
          />

          <VodPerspectiveRadio
            perspectives={perspectives}
            selected={selected}
            onSelect={setSelected}
          />
        </div>
      </AudioContextProvider>
    </StreamsContextProvider>
  );
};
