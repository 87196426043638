import {useMemo} from 'react';
import {useStreams} from '../../context/StreamsContext';
import {PerspectiveState} from '../../types/Event';
import utilStyles from '../../util/util.module.scss';
import {PerspectiveTile} from '../PerspectiveTile/PerspectiveTile';
import {PerspectiveRadioProps} from './types';

export const PerspectiveRadio: React.FC<PerspectiveRadioProps> = ({
  eventState,
  event,
  perspectives,
  selected,
  onSelect,
  socket,
}) => {
  const {streams, recordChange, currentPlayingVideos} = useStreams();

  const hasNextPerspective = useMemo(() => {
    return Object.values(perspectives).some(
      p => p.perspectiveState === PerspectiveState.Live,
    );
  }, [perspectives]);

  const handleClick = (id: string) => {
    onSelect(id);
    if (selected !== id) {
      recordChange('switched_video', id);
    }
  };

  const sortedTiles = Object.entries(perspectives)
    .filter(([, p]) => p.perspectiveState === PerspectiveState.Live)
    .sort(([, p1], [, p2]) => p1.order - p2.order)
    .map(([id, p]) => (
      <PerspectiveTile
        streams={streams}
        currentPlayingVideos={currentPlayingVideos}
        recordChange={recordChange}
        eventState={eventState}
        eventSponsorship={event.sponsorship || null}
        key={id}
        perspective={p}
        hasNextPerspective={hasNextPerspective}
        selected={selected === id}
        onClick={() => handleClick(id)}
        socket={socket}
      />
    ));

  return (
    <div
      className={`mx-auto mt-7 flex h-fit max-w-full items-start justify-start gap-7 overflow-x-auto px-6 ml:p-3 mp:mt-0 mp:block mp:p-3
        ${utilStyles.scrollbar}`}
    >
      {sortedTiles}
    </div>
  );
};
