import cx from 'classnames';
import moment from 'moment-timezone';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {browserName, deviceType, osName} from 'react-device-detect';
import offlineBg from '../../assets/images/offline-bg.svg';
import {useStreams} from '../../context/StreamsContext';
import {EventState, PerspectiveState} from '../../generated/graphql';
import useInterval from '../../graphql/hooks/useInterval';
import {useElementDimensions} from '../../hooks/useElementDimensions';
import {useSocketFunctions} from '../../hooks/useSocket';
import usePrevious from '../../hooks/usePrevious';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {useCustomEvent} from '../../pages/Audience/Audience';
import {calculateStreamStyle, isPortrait} from '../../util/responsive';
import {sendAnalytics} from '../../util/sendAnalytics';
import utilStyles from '../../util/util.module.scss';
import {CalendarButton} from '../Buttons/CalendarButton/CalendarButton';
import {CommunityTab} from '../CommunityTab/CommunityTab';
import {PerspectiveRadio} from '../PerspectiveRadio/PerspectiveRadio';
import {StreamControls} from '../StreamControls/StreamControls';
import {StreamEnded} from '../StreamEnded/StreamEnded';
import {StreamStack} from '../StreamStack/StreamStack';
import {Tab} from '../Tab/Tab';
import {TimerContainer} from '../Timer/TimerContainer';
import styles from './StreamViewer.module.scss';
import {StreamViewerProps} from './types';
import {LeftSponsorLogo} from '../SponsorLogo/LeftSponsorLogo';
import {Perspective} from '../../types/Event';
import {getUserId} from '../../util/getUserId';
import {useParams, Params} from 'react-router-dom';
import stayTuned from '../../assets/images/stay-tuned.png';
import {usePerspectives} from '../../context/PerspectiveContext';

export const StreamViewer: React.FC<StreamViewerProps> = ({
  isFullScreenState,
  setIsFullScreenState,
  event,
  mainAudio,
  mainVideo,
  perspectives,
  isCommunityEnabled,
  communityTabItems,
  toggleCommunityTab,
  title,
  startTime,
  timezone,
  communityTabShown,
}) => {
  const {eventState} = event;
  const blackBarsRef = useRef<HTMLDivElement>(null);
  const blackBarsDim = useElementDimensions(blackBarsRef);
  const [aspectRatio, setAspectRatio] = useState<number>(16 / 9);
  const {
    currentPlayingVideos,
    currentStreamDimensions,
    setCurrentStreamDimensions,
  } = useStreams();
  const isCustomEvent = useCustomEvent();

  // Compute the aspect ratio of the available space around the video player.
  // If it is greater than 16:9, we must add black bars on the left and right.
  // Otherwise, we add bars on the top and bottom.  This can be replaced
  // entirely with CSS when they add container queries.
  const vertBlackBars =
    blackBarsDim && blackBarsDim.width / blackBarsDim.height > aspectRatio;

  const {width, height} = useWindowDimensions();

  const isSmallScreen = width <= 640 || height <= 640;

  const [selectedTab, setSelectedTab] = useState<string>('Perspectives');
  const [currentPerspectiveNum, setCurrentPerspectiveNum] = useState<number>(0);
  const [currentPerspective, setCurrentPerspective] =
    useState<Perspective | null>(null);

  //calculate the end time by adding 1 hour to the start time
  const endTime = startTime.clone().add({hours: 1});

  //generate the date and time components needed for add to calendar button
  const calendarButtonStartTime = moment
    .tz(startTime, timezone)
    .format('HH:mm');
  const calendarButtonEndTime = moment.tz(endTime, timezone).format('HH:mm');
  const calendarButtonStartDate = moment
    .tz(startTime, timezone)
    .format('YYYY-MM-DD');
  const calendarButtonEndDate = moment
    .tz(endTime, timezone)
    .format('YYYY-MM-DD');

  //convert start time to number of milliseconds from current time
  const eventTimeInSeconds = startTime.unix() * 1000;
  const [startTimeReached, setStartTimeReached] = useState(false);

  const [playing, setPlaying] = useState(true);
  const [showInitialMuteButton, setShowInitialMuteButton] = useState(true); //used to determine when to show "Tap to Unmute" button

  //regularly checks amount of time before event start time
  const checkTimeRemaining = () => {
    let timeRemaining: number = eventTimeInSeconds - Date.now();
    if (timeRemaining <= 0) {
      setStartTimeReached(true);
    }
  };

  //timer to update time every 1s and check if event time has been reached
  useInterval({callback: checkTimeRemaining, duration: 1000});

  const live = useMemo(() => {
    const atLeastOneLive = Object.values(perspectives).some(
      p => p.perspectiveState === PerspectiveState.Live,
    );
    return eventState === EventState.Live && atLeastOneLive;
  }, [eventState, perspectives]);

  const prevState = usePrevious(live);
  const {perspectiveId: selected, setPerspectiveId: setSelected} =
    usePerspectives();

  const currPerspectiveWebsocket = useRef(selected);
  const userId = getUserId();
  const accessCode = useParams<Params<string>>().accessCode;
  //socket functions custom hook
  const {switchPerspectives, sendUserData, socket} = useSocketFunctions({
    currPerspectiveWebsocket,
    selected,
    source: 'Web',
    userId,
    accessCode,
  });

  useEffect(() => {
    const perspectiveArray = Object.values(perspectives);
    const selectedPerspective = perspectiveArray.find(
      perspective => perspective.order === currentPerspectiveNum,
    );
    if (selectedPerspective) {
      setCurrentPerspective(selectedPerspective);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPerspectiveNum]);

  useEffect(() => {
    sendUserData();
    return () => {
      // Disconnect from the socket when the component unmounts
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //updates viewers and sets live state based on event state
  useEffect(() => {
    if (!live) {
      //we don't want to disconnect. Instead we want to decrement current perspective and set to null
      switchPerspectives();
      setSelected(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventState, live, selected]);

  const handleSelectTab = useCallback(
    (tab: string) => {
      if (selectedTab !== tab) {
        setSelectedTab(tab);
        toggleCommunityTab();
      }
    },
    [selectedTab, toggleCommunityTab],
  );

  useEffect(() => {
    if (live) {
      switchPerspectives();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, socket, live, eventState]);
  // Detects when user starts watching a live event, or when the live event ends
  useEffect(() => {
    if (live) {
      const type = osName + ' ' + deviceType;
      sendAnalytics({
        description: 'session_start',
        device: type,
        browser: browserName,
      });
    } else if (prevState && !live)
      sendAnalytics({description: 'session_end', cause: 'stream offline'});
    // If prevState added to dependency array every event will fire twice
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [live]);

  const setStreamingDimensions = (selectedPerspective: string) => {
    const currentSelected = perspectives[selectedPerspective];
    if (currentSelected.perspectiveState === PerspectiveState.Live) {
      const currentPlayer = currentPlayingVideos.find(
        player => player.playbackId === currentSelected.playbackId,
      );
      currentPlayer?.video.addEventListener('loadeddata', () => {
        const videoWidth = currentPlayer?.video.videoWidth;
        const videoHeight = currentPlayer?.video.videoHeight;
        setCurrentStreamDimensions({
          videoHeight,
          videoWidth,
        });
      });
      if (currentPlayer?.video.videoWidth !== 0) {
        setCurrentStreamDimensions({
          videoHeight: currentPlayer?.video.videoHeight,
          videoWidth: currentPlayer?.video.videoWidth,
        });
      }
    }
  };

  useEffect(() => {
    if (selected) {
      setStreamingDimensions(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlayingVideos]);

  useEffect(() => {
    if (selected) {
      setStreamingDimensions(selected);
      const currOrder = perspectives[selected].order;
      setCurrentPerspectiveNum(currOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, perspectives]);

  useEffect(() => {
    if (selected) {
      if (width > 640) {
        setAspectRatio(9 / 16);
      } else {
        setAspectRatio(16 / 9);
      }
    }
  }, [selected, width, currentStreamDimensions]);

  useEffect(() => {
    if (eventState === EventState.Ended) {
      setCurrentStreamDimensions({
        videoWidth: 0,
        videoHeight: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventState]);

  const leftSponsorshipLogoProps = () => {
    const hasEventSponsorship = event.sponsorship
      ? Object.keys(event.sponsorship).length > 0
      : false;
    const perspectiveSponsorship = {
      logo: currentPerspective?.sponsorship?.logo,
      title: currentPerspective?.sponsorship?.title,
    };
    return {
      live: live,
      hasEventSponsorship,
      perspectiveSponsorship,
      perspectiveTitle: currentPerspective?.title,
    };
  };

  useEffect(() => {
    if (aspectRatio.valueOf() === 16 / 9) {
      setTimeout(() => window.scrollTo(0, 0), 100);
    } else if (
      isFullScreenState &&
      isPortrait(
        currentStreamDimensions?.videoHeight,
        currentStreamDimensions?.videoWidth,
      )
    ) {
      setTimeout(() => setIsFullScreenState(false), 100);
      setTimeout(() => setIsFullScreenState(true), 300);
    } else if (aspectRatio.valueOf() === 9 / 16) {
      setTimeout(() => window.scrollTo(0, 0), 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aspectRatio]);

  //Exits full screen and fixes landscape bug
  useEffect(() => {
    if (isFullScreenState) {
      setTimeout(() => window.scrollTo(0, 0));
    }
  }, [isFullScreenState]);

  const stream = (isFullScreen: boolean) => {
    return (
      <div
        className={cx(
          'flex w-full grow flex-col items-center justify-center',
          'ml:m-auto',
          'mp:top-0 mp:left-0',
          'mp:!min-h-[auto]',
          'transition-all duration-300',
          'z-[2]',
          'relative',
        )}
        ref={blackBarsRef}
        style={
          isFullScreen
            ? {
                height: '95vh',
              }
            : {
                width: calculateStreamStyle({
                  measurementType: 'width',
                  isSmallScreen,
                  width,
                  height,
                  aspectRatio,
                  currentStreamDimensions,
                }),
                height: calculateStreamStyle({
                  measurementType: 'height',
                  isSmallScreen,
                  width,
                  height,
                  aspectRatio,
                  currentStreamDimensions,
                }),
              }
        }
      >
        {event.eventState === EventState.Live &&
          Object.values(perspectives).filter(
            p => p.perspectiveState === PerspectiveState.Live,
          ).length === 0 && (
            <img className="rounded-md" src={stayTuned} alt="stay tuned" />
          )}
        <div
          className={cx(
            styles.StreamViewer,
            vertBlackBars === null
              ? 'w-0'
              : vertBlackBars
              ? `h-full`
              : `w-full`,
            eventState === EventState.NotStarted && ['bg-cover', 'bg-center'],
            eventState === EventState.NotStarted
              ? 'bg-ic-blue-100'
              : 'bg-ic-blue-400',
            `${
              currentStreamDimensions &&
              isPortrait(
                currentStreamDimensions.videoHeight,
                currentStreamDimensions.videoWidth,
              ) &&
              selected &&
              perspectives[selected].perspectiveState !==
                PerspectiveState.Offline
                ? `aspect-[9/16] ${
                    isFullScreen
                      ? isPortrait(
                          currentStreamDimensions.videoHeight,
                          currentStreamDimensions.videoWidth,
                        ) && aspectRatio.valueOf() === 9 / 16
                        ? 'ml:w-1/3'
                        : 'w-full'
                      : 'ml:w-1/3 portrait:w-1/2'
                  }`
                : 'aspect-video overflow-hidden ml:w-full'
            }`,
            `${
              isFullScreen &&
              !isPortrait(
                currentStreamDimensions.videoHeight,
                currentStreamDimensions.videoWidth,
              ) &&
              aspectRatio.valueOf() === 16 / 9
                ? 'ml:w-1/3'
                : 'rounded-lg ml:h-full ml:rounded-none mp:h-full'
            }`,
            utilStyles.safariClip,
          )}
          style={{
            backgroundImage:
              eventState === EventState.NotStarted ? `url(${offlineBg})` : '',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {eventState === EventState.NotStarted && !startTimeReached ? (
            <TimerContainer
              startTime={startTime}
              isLargeScreen={width > 640 && height > 640}
            />
          ) : (
            <></>
          )}
          <StreamStack
            isFullScreenState={isFullScreenState}
            live={live}
            event={event}
            perspectives={perspectives}
            mainAudio={mainAudio}
            mainVideo={mainVideo}
            selected={selected}
            setSelected={setSelected}
            currentStreamDimensions={currentStreamDimensions}
            communityTabShown={communityTabShown}
            playing={playing}
            setShowInitialMuteButton={setShowInitialMuteButton}
          />
          {eventState === EventState.Ended && <StreamEnded eventEnded />}
        </div>
        {!isFullScreen && currentPerspective && (
          <LeftSponsorLogo {...leftSponsorshipLogoProps()} />
        )}
        {live && (
          <>
            <StreamControls
              perspectives={perspectives}
              selected={selected}
              setSelected={setSelected}
              playing={playing}
              onPause={() => setPlaying(false)}
              onPlay={() => setPlaying(true)}
              showInitialMuteButton={showInitialMuteButton}
              setShowInitialMuteButton={setShowInitialMuteButton}
              currentPerspective={currentPerspectiveNum}
              socket={socket}
              isFullScreenState={isFullScreenState}
              setIsFullScreenState={setIsFullScreenState}
            />
          </>
        )}
      </div>
    );
  };

  if (!isFullScreenState)
    return (
      <div
        className={cx(
          'grid h-full max-w-full shrink select-none ml:grid-rows-1 mp:block',
          styles.viewerGrid,
        )}
      >
        <div className={'flex h-full w-full grow'}>{stream(false)}</div>
        <Tab
          isCustomEvent={isCustomEvent}
          eventState={eventState}
          eventTitle={event.title}
          selectedTab={selectedTab}
          isCommunityEnabled={isCommunityEnabled}
          onClickOnPerspectivesTab={() => handleSelectTab('Perspectives')}
          onClickOnCommunityTab={() => handleSelectTab('Community')}
          eventSponsorship={event.sponsorship}
          socket={socket}
          perspectives={perspectives}
          currentPerspective={currentPerspective}
        />

        {width > 640 && height > 640 ? (
          event.eventState === EventState.NotStarted && !startTimeReached ? (
            <div className={cx(`mx-4 my-8 flex justify-center`)}>
              <div className={cx(`max-w-xs flex-col justify-center`)}>
                <h3 className={cx('my-1 text-2xl font-bold text-white')}>
                  Not ready yet!
                </h3>
                <p className={cx('mb-4 text-neutral-400')}>
                  Make sure to save the event to your calendar so you know when
                  to come back
                </p>
                <CalendarButton
                  title={title}
                  startDate={calendarButtonStartDate}
                  endDate={calendarButtonEndDate}
                  startTime={calendarButtonStartTime}
                  endTime={calendarButtonEndTime}
                  timezone={timezone}
                  eventLink={window.location.href}
                ></CalendarButton>
              </div>
              <div className={cx('h-full shrink-0')}>
                <img
                  src={require('../../assets/images/incrowd-dog1.png')}
                  className={cx('h-full')}
                  alt="incrowd-dog"
                />
              </div>
            </div>
          ) : (
            <PerspectiveRadio
              eventState={eventState}
              perspectives={perspectives}
              selected={selected}
              onSelect={setSelected}
              event={event}
              socket={socket}
            />
          )
        ) : selectedTab === 'Perspectives' ? (
          event.eventState === EventState.NotStarted && !startTimeReached ? (
            <div
              className={cx(`align-center mx-4 my-8 flex-col justify-center`)}
            >
              <div className={cx('align-center flex justify-center')}>
                <CalendarButton
                  title={title}
                  startDate={calendarButtonStartDate}
                  endDate={calendarButtonEndDate}
                  startTime={calendarButtonStartTime}
                  endTime={calendarButtonEndTime}
                  timezone={timezone}
                  eventLink={window.location.href}
                />
              </div>
              <div className={cx('align-center mb-8 shrink-0')}>
                <img
                  src={require('../../assets/images/incrowd-dog1.png')}
                  alt="incrowd-dog"
                />
              </div>
              <div className={cx(`mx-auto w-3/4 flex-col justify-center`)}>
                <h3
                  className={cx(
                    'my-1 text-center text-2xl font-bold text-white',
                  )}
                >
                  Not ready yet!
                </h3>
                <p className={cx('mb-4 text-center text-neutral-400')}>
                  Make sure to save the event to your calendar so you know when
                  to come back
                </p>
              </div>
            </div>
          ) : (
            <PerspectiveRadio
              eventState={eventState}
              perspectives={perspectives}
              selected={selected}
              onSelect={setSelected}
              event={event}
              socket={socket}
            />
          )
        ) : (
          <CommunityTab communityTabItems={communityTabItems} />
        )}
      </div>
    );
  else
    return (
      <div className={cx('h-full w-full', styles.viewerGrid)}>
        <div className={'flex h-full w-full grow'}>{stream(true)}</div>
      </div>
    );
};
