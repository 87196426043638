import {EventNotFoundProps} from './types';

const EventNotFound: React.FC<EventNotFoundProps> = ({accessCode}) => {
  return (
    <div className="screen-height flex flex-col items-center justify-center bg-black p-8 text-xl text-white">
      <p>
        Could not find an event with the access code <code>{accessCode}</code>.
      </p>
      <p>Check the link to make sure you typed it correctly.</p>
    </div>
  );
};

export default EventNotFound;
