import {FC} from 'react';
import {Card} from '../../Card/Card';
import {CommunityLink} from '../CommunityLink/CommunityLink';
import {CommunityCardProps} from './types';

export const CommunityCard: FC<CommunityCardProps> = ({
  imgSource,
  favIconSource,
  favIconAlt,
  altText,
  url,
  label,
  buttonStyles,
  textStyle,
  heading,
}) => {
  return (
    <Card imgSource={imgSource} altText={altText}>
      <CommunityLink
        url={url}
        favIconSource={favIconSource}
        favIconAlt={favIconAlt}
        label={label}
        textStyle={textStyle}
        buttonStyles={buttonStyles}
        heading={heading}
      />
    </Card>
  );
};
