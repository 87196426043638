import {useState} from 'react';
import {PerspectiveState} from '../generated/graphql';

export const useVideoPlayerDimensions = (
  perspective: {perspectiveState: PerspectiveState; playbackId: any},
  currentPlayingVideos: any[],
) => {
  const [tileDimensions, setTileDimensions] = useState<any>({});

  const getTileDimensions = () => {
    if (perspective && perspective.perspectiveState === PerspectiveState.Live) {
      const currentPlayer = currentPlayingVideos.find(
        (player: {playbackId: any}) =>
          player.playbackId === perspective.playbackId,
      );
      currentPlayer?.video.addEventListener('loadeddata', () => {
        const videoWidth = currentPlayer?.video.videoWidth;
        const videoHeight = currentPlayer?.video.videoHeight;
        setTileDimensions({
          videoHeight: videoHeight,
          videoWidth: videoWidth,
        });
      });
    }
  };
  return {tileDimensions, getTileDimensions};
};
