import {getUserId} from './getUserId';

export async function sendAnalytics(meta: any) {
  const accessCode = window.location.pathname.replace('/e/', '');
  const url = process.env.REACT_APP_REST_API;
  const createdAt = new Date().toISOString();
  if (!url) throw new Error('Environment variables not set correctly');

  const userId = getUserId();
  meta['user'] = userId;
  await fetch(url + '/analytics', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({accessCode, source: 'Web', createdAt, meta}),
  });
}
