import {forwardRef, useEffect, useState} from 'react';
import {useStreams} from '../../context/StreamsContext';
import {setPlayerSource} from '../../util/mux';
import Player from '../Player/Player';
import {useInitPlayer} from '../../hooks/stream/useInitPlayer';
import {useSelectQuality} from '../../hooks/stream/useSelectQuality';
import {StreamProps} from './types';
import {StreamRef} from '../../hooks/stream/types';

export const Stream = forwardRef<StreamRef, StreamProps>(
  (
    {
      eventTitle,
      perspective,
      muted,
      setMuted,
      playing,
      autoplay,
      reduceQuality,
      endStream,
    },
    stream,
  ) => {
    const {player, playerRef} = useInitPlayer({
      eventTitle,
      perspectiveTitle: perspective.title,
      playbackId: perspective.playbackId,
    });
    const [autoPlaying, setAutoPlaying] = useState(autoplay ?? false);
    const {currentPlayingVideos, setCurrentPlayingVideos} = useStreams();

    // Select the lowest quality stream if "reduceQuality" is selected.
    useSelectQuality(player?.videoTracks[0], reduceQuality ?? false);

    // If we are autoPlaying muted, we need to propagate this information up.
    useEffect(() => {
      if (player && setMuted) {
        const listener = () => setMuted(player.muted);
        player.addEventListener('playing', listener);
        return () => player.removeEventListener('playing', listener);
      }
    }, [player, setMuted]);

    // Set the source when the stream URL changes/is set for the first time.
    useEffect(() => {
      if (player) {
        if (
          currentPlayingVideos.some(
            item => item.playbackId === perspective.playbackId,
          )
        ) {
          const filteredPlayers = currentPlayingVideos.filter(
            item => item.playbackId !== perspective.playbackId,
          );
          setCurrentPlayingVideos(filteredPlayers);
        }

        setCurrentPlayingVideos(prevState => [
          ...prevState,
          {video: player, playbackId: perspective.playbackId},
        ]);

        player.source = setPlayerSource(perspective.playbackId);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [player, perspective.playbackId]);

    // Call variable on/off methods when the associated prop changes.
    if (player) {
      player.autoplay = autoplay ?? false;
      player.muted = muted ?? false;
      if (playing && !autoPlaying) {
        player.play();
      }
      if (!playing) {
        player.pause();
      }
      if (endStream) {
        player.stop();
      }
      player.volume = 1;
    }

    useEffect(() => {
      if (!playing) {
        setAutoPlaying(false);
      }
    }, [playing]);

    return (
      <>
        <Player
          stream={stream}
          perspectiveTitle={perspective.title}
          player={player}
          playerRef={playerRef}
        />
      </>
    );
  },
);
