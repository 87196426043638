import {useQuery} from '@apollo/client';
import update from 'immutability-helper';
import {
  EventDocument,
  EventState,
  EventUpdatesDocument,
  PerspectiveUpdatesDocument,
} from '../../generated/graphql';
import {UseEvent} from './types';

export function useEvent(accessCode: string): UseEvent {
  const {
    data: {getEvent: event} = {undefined},
    subscribeToMore: subscribeToEvent,
    error,
    loading,
  } = useQuery(EventDocument, {
    variables: {accessCode},
  });
  if (event?.eventState !== EventState.Ended) {
    subscribeToEvent({
      document: EventUpdatesDocument,
      variables: {accessCode},
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: {onEventUpdate},
          },
        },
      ) => {
        if (!onEventUpdate) return prev;
        const {title, eventState} = onEventUpdate;
        return update(prev, {
          getEvent: {
            ...(title && {title: {$set: title}}),
            ...(eventState && {eventState: {$set: eventState}}),
          },
        });
      },
      onError: err => {
        console.error('An event subscription error occurred', err);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        subscribeToEvent;
      },
    });

    subscribeToEvent({
      document: PerspectiveUpdatesDocument,
      variables: {accessCode},
      updateQuery: (
        prev,
        {
          subscriptionData: {
            data: {onPerspectiveUpdate},
          },
        },
      ) => {
        if (!onPerspectiveUpdate) return prev;
        const {id, perspectiveState} = onPerspectiveUpdate;
        const idx = prev.getEvent.perspectives.findIndex(p => p.id === id);
        if (idx === -1) return prev;
        return update(prev, {
          getEvent: {
            perspectives: {
              [idx]: {
                ...(perspectiveState && {
                  perspectiveState: {$set: perspectiveState},
                }),
              },
            },
          },
        });
      },
      onError: err => {
        console.error('An event subscription error occurred', err);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        subscribeToEvent;
      },
    });
  }

  return {event, error, loading};
}
