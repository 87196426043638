import React, {useState, useEffect, useCallback} from 'react';
import {ReactComponent as DownArrow} from '../../assets/images/dropdown-arrow.svg';
import {motion} from 'framer-motion';
import {EmojiReactionProps, EmojiObject} from './types';
import {useParams} from 'react-router-dom';
import {AudienceParams} from '../../pages/Audience/types';
import {emojis} from './emojis';
import {getUserId} from '../../util/getUserId';
import CrowdEmojiReactions from './CrowdEmojiReactions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {useStreams} from '../../context/StreamsContext';

const EmojiReaction: React.FC<EmojiReactionProps> = ({
  isToggled,
  setIsToggled,
  setIsHovered,
  socket,
  perspectiveId,
}) => {
  const userID = getUserId();
  const {accessCode} = useParams<keyof AudienceParams>() as AudienceParams;
  const [animations, setAnimations] = useState<EmojiObject[]>([]);
  const isDesktop = useWindowDimensions().width > 640;
  const yDisplacement = isDesktop ? -120 : -100;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMouseEnter = useCallback(
    () => setIsHovered && setIsHovered(true),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMouseLeave = useCallback(
    () => setIsHovered && setIsHovered(false),
    [],
  );

  const {getPlaybackTime} = useStreams();

  const handleToggle = () => {
    !isToggled && setIsToggled(true);
  };
  const handleClose = () => {
    setIsToggled(false);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      animations.length > 0 &&
        animations.every((item: EmojiObject) => !item.animating) &&
        setAnimations([]);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [animations]);

  const handleEmojiPress = (emojiString: string) => {
    setAnimations([...animations, {emoji: emojiString, animating: true}]);
    const time = Math.floor(new Date().getTime() / 1000);
    const playbackTime = perspectiveId
      ? getPlaybackTime(perspectiveId)
      : undefined;
    const data = {
      emoji: emojiString,
      source: 'Web',
      user: userID,
      perspectiveId,
      playbackTime,
      time,
    };
    socket.emit('sendEmoji', data, accessCode);
  };

  return (
    <>
      {!isDesktop && (
        <CrowdEmojiReactions
          socket={socket}
          selected={perspectiveId}
          isOnPerspectiveTile={false}
        />
      )}
      <div
        className="z-50 flex flex-col items-center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {animations.map(
          (item, index) =>
            item.animating && (
              <motion.div
                className="absolute"
                key={index}
                initial={{y: 10, rotate: 0}}
                animate={{y: yDisplacement, rotate: [-10, 10, -10, 10, 0]}}
                transition={{duration: 1}}
                onAnimationComplete={() => {
                  setAnimations(prevStates =>
                    prevStates.map((state, idx) =>
                      idx === index ? {...state, animating: false} : state,
                    ),
                  );
                }}
              >
                {item.emoji}
              </motion.div>
            ),
        )}
        <button
          data-testid="main-emoji-button"
          onClick={handleToggle}
          className="z-20 flex min-h-[40px] w-10 flex-col content-center items-center rounded-full border border-white bg-black/50 text-sm text-white"
        >
          {isToggled ? (
            <>
              {emojis.map(emojiItem => (
                <button
                  onClick={() => handleEmojiPress(emojiItem.emoji)}
                  className="py-2"
                  data-testid={`${emojiItem.name}-emoji-button`}
                >
                  {emojiItem.emoji}
                </button>
              ))}
              <button
                className="py-2"
                onClick={handleClose}
                data-testid="close-button"
              >
                <DownArrow />
              </button>
            </>
          ) : (
            <p className="p-2">🔥</p>
          )}
        </button>
      </div>
    </>
  );
};

export default EmojiReaction;
