import {TouchEvent, TouchEventHandler, useCallback, useRef} from 'react';

export type Direction = 'left' | 'right' | 'up' | 'down';

export interface SwipeHandlers<T> {
  onTouchStart: TouchEventHandler<T>;
  onTouchMove: TouchEventHandler<T>;
  onTouchEnd: TouchEventHandler<T>;
}

const minimumSwipe = 30;

export function useSwipe<T>(
  callback: (direction: Direction) => void,
  tapCallback: ((ev: TouchEvent<T>) => void) | undefined = undefined,
): SwipeHandlers<T> {
  const state = useRef<{start: Touch | null; end: Touch | null}>({
    start: null,
    end: null,
  });
  const onTouchStart = useCallback((ev: TouchEvent<T>) => {
    state.current.start = ev.nativeEvent.touches[0];
    state.current.end = null;
  }, []);
  const onTouchMove = useCallback((ev: TouchEvent<T>) => {
    state.current.end = ev.nativeEvent.touches[0];
  }, []);
  const onTouchEnd = useCallback(
    (ev: TouchEvent<T>) => {
      if (state.current.end === null || state.current.start === null) {
        if (tapCallback) tapCallback(ev);
        return;
      }

      const sx = state.current.start.clientX;
      const sy = state.current.start.clientY;

      const ex = state.current.end.clientX;
      const ey = state.current.end.clientY;

      const dx = ex - sx;
      const dy = ey - sy;

      if (Math.abs(dx) < minimumSwipe && Math.abs(dy) < minimumSwipe) {
        if (tapCallback) tapCallback(ev);
        return;
      }

      if (Math.abs(dx) > Math.abs(dy)) {
        return callback(dx > 0 ? 'left' : 'right');
      } else {
        return callback(dy > 0 ? 'down' : 'up');
      }
    },
    [callback, tapCallback],
  );

  return {onTouchStart, onTouchMove, onTouchEnd};
}
