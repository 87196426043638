import {VodPerspectiveTitleProps} from './types';

export const VodPerspectiveTitle: React.FC<VodPerspectiveTitleProps> = ({
  live,
  perspectiveTitle,
}) => {
  return (
    <span className={'mp:ml-4'}>
      <h3 className={`text-sm ${live ? 'text-white' : 'text-ic-blue-600'}`}>
        {perspectiveTitle}
      </h3>
    </span>
  );
};
