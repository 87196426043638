import cx from 'classnames';
import {useCallback, useEffect, useRef, useState} from 'react';
import {isIOS, isSafari} from 'react-device-detect';
import {ReactComponent as SpinLoaderIcon} from '../../assets/images/loader.svg';
import {useAudio} from '../../context/AudioContext';
import {useStreams} from '../../context/StreamsContext';
import {PerspectiveState} from '../../types/Event';
import {VodStream} from '../Stream/Vod';
import {VodStackProps} from './types';
import {StreamRef} from '../../hooks/stream/types';

/**
 * A "stack" of synchronized video players, where one video is shown at a time.
 */
export const VodStack: React.FC<VodStackProps> = ({
  eventTitle,
  perspectives,
  selected,
  setSelected,
  mainVideo,
  mainAudio,
  live,
  setShowInitialMuteButton,
  playing,
}) => {
  const {streams} = useStreams();
  // We only need to recognize that we're muted from auto playing once.
  const [autoPlaying, setAutoPlaying] = useState(true);
  const {audioSource, setAudioSource, sourceMuted, setSourceMuted} = useAudio();
  const mainAudioLive =
    !!mainAudio &&
    perspectives[mainAudio].perspectiveState === PerspectiveState.Live;

  const mainVideoLive =
    !!mainVideo &&
    perspectives[mainVideo].perspectiveState === PerspectiveState.Live;

  // Checks if the current sound/video source is live
  const isAudioStreaming =
    !!audioSource &&
    perspectives[audioSource].perspectiveState === PerspectiveState.Live;

  // When the perspective ends, switch to the next available stream.
  const handleSwitchToNext = useCallback(
    (next: string) => {
      const ps = Object.values(perspectives);
      ps.sort((x, y) => x.order - y.order);
      const first = ps.find(p => p.perspectiveState === PerspectiveState.Live);
      if (first) {
        next === 'video' ? setSelected(first.id) : setAudioSource(first.id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setAudioSource, setSelected, perspectives],
  );

  useEffect(() => {
    if (
      selected &&
      perspectives[selected].perspectiveState === PerspectiveState.Offline
    ) {
      handleSwitchToNext('video');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perspectives, selected]);

  // If we have no selected stream, select on according to the following rules:
  // - Prefer the main perspective
  // - Select the first-ordered online stream otherwise
  useEffect(() => {
    if (selected) return;

    return mainVideoLive ? setSelected(mainVideo) : handleSwitchToNext('video');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  // Logic to control the active sound source
  // If a perspective that is NOT the current sound source joins/drops keep the current sound source
  // If the current sound source drops try to set the sound to mainAudio, otherwise find the first live perspective
  // If there are no live perspectives, set the sound source to be undefined
  // Miro board mapping the Context used here: https://miro.com/app/board/uXjVOiyselQ=/?share_link_id=525382542075

  useEffect(() => {
    if (!live) return setAudioSource('');

    if (isAudioStreaming) return;

    if (mainAudioLive) {
      setAudioSource(mainAudio);
    } else {
      handleSwitchToNext('audio');
    }

    if (isIOS && isSafari) {
      setSourceMuted(true);
      setShowInitialMuteButton(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [live, perspectives, mainAudioLive]);

  const streamStackRef = useRef<HTMLDivElement | null>(null);
  return (
    <div ref={streamStackRef} className="grid h-full w-full">
      <div className="z-0 col-start-1 row-start-1 grid place-content-center">
        {live && playing && (
          <SpinLoaderIcon className="h-10 w-10 animate-spin" />
        )}
      </div>
      {Object.values(perspectives).map(p => (
        <div
          key={p.id}
          className={cx(
            selected === p.id && live ? 'block' : 'hidden',
            'z-10 col-start-1 row-start-1 max-h-full max-w-full overflow-hidden',
          )}
        >
          {p.perspectiveState === PerspectiveState.Live && (
            <VodStream
              ref={el => {
                streams.current[p.id] = el;
              }}
              eventTitle={eventTitle}
              muted={audioSource !== p.id || sourceMuted}
              playing={playing}
              setMuted={m => {
                if (selected === p.id && autoPlaying) {
                  setAutoPlaying(false);
                  setSourceMuted(m);
                }
              }}
              reduceQuality={selected !== p.id}
              perspectiveId={p.id}
              perspectiveTitle={p.title}
            />
          )}
        </div>
      ))}
    </div>
  );
};
