import cx from 'classnames';
import {useCallback, useEffect, useRef, useState} from 'react';
import {ReactComponent as PlayIcon} from '../../assets/images/big-play.svg';
import {ReactComponent as MutedIcon} from '../../assets/images/mute.svg';
import {ReactComponent as PauseIcon} from '../../assets/images/pause.svg';
import {ReactComponent as SkipIcon} from '../../assets/images/skip.svg';
import {useAudio} from '../../context/AudioContext';
import {useVod} from '../../context/VodContext';
import {Direction, useSwipe} from '../../hooks/useSwipe';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import CrowdEmojiReactions from '../EmojiReaction/CrowdEmojiReactions';
import EmojiReaction from '../EmojiReaction/EmojiReaction';
import {MutedIconContainer} from './MutedIconContainer';
import {VolumeIconContainer} from './VolumeIconContainer';
import {StreamControlsProp} from './types';
import FullScreenControl from './FullScreenControl';
import Scrubber from './Scrubber';

export const StreamControls: React.FC<StreamControlsProp> = ({
  setIsFullScreenState,
  isFullScreenState,
  perspectives,
  playing,
  selected,
  setSelected,
  onPause,
  onPlay,
  showInitialMuteButton,
  setShowInitialMuteButton,
  currentPerspective,
  socket,
  isVod,
}) => {
  const [controlsShown, setControlsShown] = useState(false);
  const {audioSource, sourceMuted, setSourceMuted, toggleMute} = useAudio();
  const timer = useRef<NodeJS.Timer | null>(null);
  const [soundIcon, setSoundIcon] = useState<JSX.Element>();
  const {width, height} = useWindowDimensions();
  const isPortrait = height > width;
  const {rewindEvent, forwardEvent} = useVod();
  const [isToggled, setIsToggled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleControlsShown = useCallback(async () => {
    !showInitialMuteButton && setControlsShown(true);
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (!isHovered) {
        setControlsShown(false);
        setIsToggled(false);
      }
    }, 3000);
  }, [showInitialMuteButton, isHovered]);

  useEffect(() => {
    handleControlsShown();

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [handleControlsShown]);

  useEffect(() => {
    if (!sourceMuted) setShowInitialMuteButton(false);
  }, [setShowInitialMuteButton, sourceMuted]);

  useEffect(() => {
    const icon = (
      <button
        className={cx(
          'flex rounded-full mp:border mp:border-white/50 mp:bg-black/50 mp:px-2 mp:py-1',
          isPortrait && 'absolute bottom-4',
        )}
        onClick={toggleMute}
      >
        {sourceMuted ? (
          <MutedIconContainer />
        ) : (
          <VolumeIconContainer
            perspectiveTitle={perspectives[audioSource].title}
          />
        )}
      </button>
    );
    setSoundIcon(icon);
  }, [
    sourceMuted,
    audioSource,
    toggleMute,
    perspectives,
    isPortrait,
    width,
    height,
  ]);

  const hideControls = useCallback(() => {
    if (controlsShown) {
      setControlsShown(false);
    }
  }, [setControlsShown, controlsShown]);

  const onSwipe = useCallback(
    (dir: Direction) => {
      const ps = Object.values(perspectives).filter(p => p.perspectiveState);
      ps.sort((x, y) => x.order - y.order);
      const current = ps.findIndex(p => p.id === selected);
      if (current === -1) return;
      const d = dir === 'right' ? 1 : dir === 'left' ? -1 : null;
      if (!d) return;
      const i = (current + d) % ps.length;
      const next = ps[i < 0 ? ps.length - 1 : i].id;
      setSelected(next);
    },
    [perspectives, selected, setSelected],
  );

  const swipeHandlers = useSwipe(onSwipe, hideControls);

  const isDesktop = !('ontouchstart' in window || navigator.maxTouchPoints > 0);

  return playing ? (
    <>
      <div
        className={cx(
          'absolute bottom-0 h-full w-full',
          'z-20 col-start-1 row-start-1 flex flex-col items-center justify-end',
        )}
        {...swipeHandlers}
        onMouseEnter={isDesktop ? handleControlsShown : undefined}
        onMouseMove={isDesktop ? handleControlsShown : undefined}
        onTouchEnd={handleControlsShown}
      >
        {showInitialMuteButton && sourceMuted && (
          <div
            className={cx(`${currentPerspective === 2 && 'w-42'}
              absolute bottom-0 m-4 flex cursor-pointer rounded-full bg-ic-blue-500 hover:brightness-200 active:brightness-100`)}
            onClick={() => {
              setSourceMuted(false);
              setShowInitialMuteButton(false);
            }}
          >
            <div className={cx('flex px-4 py-2')}></div>
            <div className={cx('flex px-4 py-2')}>
              <MutedIcon className="mr-2" />
              Tap to unmute
            </div>
          </div>
        )}
        <div className="absolute bottom-1 ml-64 flex">
          <FullScreenControl
            isFullScreenState={isFullScreenState}
            setIsFullScreenState={setIsFullScreenState}
          />
        </div>
        <div
          className={cx(
            controlsShown
              ? 'opacity-100'
              : playing && 'pointer-events-none opacity-0',
          )}
        >
          {isVod ? <Scrubber /> : null}
        </div>
        <div
          className={cx(
            'flex w-full bg-gradient-to-t from-black/75 p-4 transition-opacity md:max-h-[64px] mp:mb-0 mp:h-full mp:flex-col mp:bg-none',
            isDesktop ? '' : 'items-center',
            isPortrait && 'relative',
            controlsShown
              ? 'opacity-100'
              : playing && 'pointer-events-none opacity-0',
          )}
        >
          <div
            className={cx(
              'flex items-center',
              isDesktop ? 'flex-row' : 'h-full w-full justify-center',
            )}
          >
            <button
              onClick={onPause}
              className={cx(
                'mr-4 w-auto mp:my-auto mp:mr-0 mp:h-20 mp:w-20 mp:rounded-full mp:border mp:border-ic-blue-500 mp:bg-black/50',
                isPortrait && 'absolute top-1/2 -translate-y-1/2 transform',
              )}
            >
              <PauseIcon
                onClick={onPause}
                className={cx(
                  'mp:h-13 mp:w-13 hover:brightness-75 active:brightness-50 mp:m-auto',
                )}
              />
            </button>
            {isVod && (
              <>
                <button
                  onClick={rewindEvent}
                  className={cx(
                    'mr-4 w-auto mp:my-auto mp:mr-0 mp:h-20 mp:w-20 mp:rounded-full mp:border mp:border-ic-blue-500 mp:bg-black/50',
                    isPortrait && 'absolute top-1/2 -translate-y-1/2 transform',
                  )}
                >
                  <SkipIcon
                    className={cx(
                      'mp:h-13 mp:w-13 h-7 w-7 hover:brightness-75 active:brightness-50 mp:m-auto',
                    )}
                  />
                </button>
                <button
                  onClick={forwardEvent}
                  className={cx(
                    'mr-4 w-auto mp:my-auto mp:mr-0 mp:h-20 mp:w-20 mp:rounded-full mp:border mp:border-ic-blue-500 mp:bg-black/50',
                    isPortrait && 'absolute top-1/2 -translate-y-1/2 transform',
                  )}
                >
                  <SkipIcon
                    className={cx(
                      'mp:h-13 mp:w-13 h-7 w-7 rotate-180 hover:brightness-75 active:brightness-50 mp:m-auto',
                    )}
                  />
                </button>
              </>
            )}
            {soundIcon}
          </div>
          {isDesktop && !isVod && (
            <EmojiReaction
              isToggled={isToggled}
              setIsToggled={setIsToggled}
              setIsHovered={setIsHovered}
              perspectiveId={selected}
              socket={socket}
            />
          )}
        </div>
      </div>
      {isDesktop && !isVod && (
        <CrowdEmojiReactions
          selected={selected}
          socket={socket}
          isOnPerspectiveTile={false}
        />
      )}
    </>
  ) : (
    <div
      className={cx(
        'z-20 col-start-1 row-start-1 flex flex-col items-center justify-center bg-black/50',
        'absolute h-full w-full',
      )}
    >
      <PlayIcon
        onClick={onPlay}
        className={cx(
          !isDesktop && 'h-20 w-20',
          'hover:brightness-75 active:brightness-50',
        )}
      />
    </div>
  );
};
