import {ChromelessPlayer} from '@theoplayer/basic-hls';
import {ForwardedRef, useImperativeHandle, useMemo, useState} from 'react';
import * as portals from 'react-reverse-portal';
import styles from './Stream.module.scss';
import {StreamRef} from './types';
import cx from 'classnames';

export const useManageStream = (
  stream: ForwardedRef<StreamRef>,
  player: ChromelessPlayer | null,
  title: string,
) => {
  const portalNode = useMemo(() => {
    const node = portals.createHtmlPortalNode();
    node.element.className = cx('w-full h-full', styles.player);
    return node;
  }, []);
  // If thumbnailed, it is rendered through the portal into a tile.
  const [thumbnailed, setThumbnailed] = useState(false);

  /*
   * Adds functionality to a stream reference sent by a parent component.
   * This functionality can be used to manage and get information from a specific stream
   */
  useImperativeHandle(
    stream,
    () => ({
      synchronize: syncStream => {
        if (!player) return;
        const syncTime = syncStream?.pdt()?.getTime();
        const playerTime = player?.currentProgramDateTime?.getTime();
        if (syncTime && playerTime) {
          const playbackTimeDiff = syncTime - playerTime;
          const syncThreshold = 100; // milliseconds
          const behindThreshold = -30000; // behind by 30 seconds
          if (Math.abs(playbackTimeDiff) < syncThreshold) return;
          else if (playbackTimeDiff < behindThreshold) return;
        } else return;

        const listener = () => {
          player.play();
          player.removeEventListener('seeked', listener);
        };

        // REF: https://demo.theoplayer.com/synchronizing-multiple-players
        player.pause();
        const pdt = syncStream?.pdt(); // playback position of media as timestamp
        if (pdt) player.currentProgramDateTime = pdt; // Changes seeking attribute to true
        player.addEventListener('seeked', listener); // Calls callback once seeking is false
      },

      unfreeze: () => {
        if (player) player.play();
      },

      pdt: () => player?.currentProgramDateTime ?? null,

      currentTime: () => player?.currentTime ?? null,

      thumbnailify: () => {
        setThumbnailed(true);
        return portalNode;
      },

      unthumbnailify: () => setThumbnailed(false),

      title: title,
    }),
    [player, portalNode, title],
  );

  return {portalNode, thumbnailed};
};
