import {AudioContextI} from './types';
import {createContext, useState, useCallback, useContext} from 'react';

export const useAudio = () => useContext(AudioContext);

export const AudioContext = createContext<AudioContextI>({
  audioSource: '',
  setAudioSource: () => {},
  sourceMuted: true,
  setSourceMuted: () => {},
  toggleMute: () => {},
});

export const AudioContextProvider: React.FC<{children?: any}> = ({
  children,
}) => {
  const [audioSource, setAudioSource] = useState('');
  const [sourceMuted, setSourceMuted] = useState(true);
  const toggleMute = useCallback(() => {
    setSourceMuted(muted => !muted);
  }, [setSourceMuted]);

  const value = {
    audioSource: audioSource,
    setAudioSource: setAudioSource,
    sourceMuted: sourceMuted,
    setSourceMuted: setSourceMuted,
    toggleMute: toggleMute,
  };

  return (
    <AudioContext.Provider value={value}>{children}</AudioContext.Provider>
  );
};
