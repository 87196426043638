import cx from 'classnames';
import {
  useFlags,
  withLDProvider,
  useLDClient,
} from 'launchdarkly-react-client-sdk';
import {useEffect, useState, useCallback} from 'react';
import TagManager from 'react-gtm-module';
import {CommunityTab} from '../../components/CommunityTab/CommunityTab';
import {Footer} from '../../components/Footer/Footer';
import {DesktopHeader} from '../../components/DesktopHeader/DesktopHeader';
import {Loader} from '../../components/Loader/Loader';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {VodViewer} from '../../components/VodViewer/VodViewer';
import {useVod} from '../../context/VodContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {MobileHeader} from '../../components/MobileHeader/MobileHeader';
import {useDateTime} from '../../graphql/hooks/useDateTime';

const Vod: React.FC = () => {
  const {accessCode, vodEvent} = useVod();
  const {width, height} = useWindowDimensions();
  const [communityTabShown, setCommunityTabShown] = useState(false);
  const {isCommunityEnabled, enableVod} = useFlags();
  const {startTime} = useDateTime(accessCode);
  const ld = useLDClient();

  const toggleCommunity = () => {
    if (!communityTabShown) {
      setCommunityTabShown(true);
      TagManager.dataLayer({
        dataLayer: {event: 'communityOpen'},
      });
    } else {
      setCommunityTabShown(false);
      TagManager.dataLayer({
        dataLayer: {event: 'communityClose'},
      });
    }
  };

  const isDimensionsLarge = width > 640 && height > 640;

  const initializeLDUser = useCallback(async () => {
    await ld?.identify({
      anonymous: true,
      custom: {eventCode: accessCode},
    });
  }, [accessCode, ld]);

  useEffect(() => {
    ld?.waitForInitialization()
      .then(() => {
        initializeLDUser();
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [initializeLDUser, ld]);

  useEffect(() => {
    setCommunityTabShown(isCommunityEnabled);
  }, [isCommunityEnabled]);

  if (vodEvent?.eventTitle) document.title = vodEvent.eventTitle;

  const isValidVodEvent = Object.keys(vodEvent).length > 0;

  if (enableVod === undefined || !isValidVodEvent) return <Loader />;

  return (
    <div className="screen-height disable-screen-height-mb flex text-white">
      <div
        className={cx(
          'grid w-full grid-rows-layout gap-4 bg-black p-8 ml:grid-rows-1 ml:p-0 mp:block mp:p-0',
        )}
      >
        {isDimensionsLarge ? (
          <DesktopHeader
            titleProps={{text: vodEvent.eventTitle, isLive: false}}
            showToast={false}
          />
        ) : (
          <MobileHeader />
        )}

        <div className={cx('flex gap-4 ml:block mp:block')}>
          <div className={cx('max-w-full grow mp:h-full')}>
            <VodViewer
              eventTitle={vodEvent.eventTitle}
              mainAudio={vodEvent.eventMainAudio ?? null}
              mainVideo={vodEvent.eventMainVideo ?? null}
              perspectives={vodEvent.perspectives}
              toggleCommunityTab={toggleCommunity}
              isCommunityEnabled={isCommunityEnabled}
              startTime={startTime}
            />
          </div>
          {!communityTabShown && isCommunityEnabled && (
            <div
              className={cx(
                'relative z-20 translate-y-[-6rem] self-center ml:hidden mp:hidden',
              )}
            >
              <Sidebar handleClick={toggleCommunity} />
            </div>
          )}
        </div>
      </div>
      {isDimensionsLarge && communityTabShown ? (
        <CommunityTab onClose={toggleCommunity} />
      ) : (
        <Footer />
      )}
    </div>
  );
};

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_SDK_CLIENT!,
  context: {
    kind: 'user',
    anonymous: true,
    key: 'eventCode',
  },
  flags: {
    isCommunityEnabled: false,
    isCustomEvent: false,
    enableVod: false,
  },
})(Vod);
