import {ReactComponent as SelectedBg} from '../../assets/images/selected-perspective.svg';
import {ReactComponent as CameraIcon} from '../../assets/images/camera.svg';
import styles from '../PerspectiveTile/PerspectiveTile.module.scss';

export const SelectedThumbnail = () => {
  return (
    <>
      <SelectedBg
        className={`${styles.bg}
            absolute top-0 bottom-0 right-0 left-0 col-start-1 row-start-1`}
        data-testid="selected-thumbnail"
      />
      <CameraIcon
        className={`${styles.camera}
            absolute top-[50%] left-[50%] col-start-1 row-start-1 translate-x-[-50%] translate-y-[-50%]`}
      />
    </>
  );
};
