import {useQuery} from '@apollo/client';
import {Moment} from 'moment';
import moment from 'moment-timezone';
import {GetDateTimeDocument} from '../../generated/graphql';
import {UseDateTime} from './types';

export function useDateTime(accessCode: string): UseDateTime {
  const {error, data: {getEvent: event} = {undefined}} = useQuery(
    GetDateTimeDocument,
    {
      variables: {accessCode},
    },
  );
  let startTime: Moment | undefined,
    timezone: string | undefined,
    title: string | undefined;

  if (event) {
    if (event.date && event.time && event.timezone) {
      timezone = event.timezone;

      //generates a date object with the correct time in the event timezone for start and end time
      const startString = `${event.date} ${event.time}`;
      startTime = moment.tz(startString, 'YYYY-MM-DD h:mm a', event.timezone);
    }
    if (event.title) {
      title = event.title;
    }
  }
  return {
    startTime,
    timezone,
    title,
    error,
  };
}
