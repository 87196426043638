import {useMemo} from 'react';
import io from 'socket.io-client';

type SocketHookProps = {
  currPerspectiveWebsocket: React.MutableRefObject<string | null>;
  source: string | null;
  selected: string | null;
  userId: string | undefined;
  accessCode: string | undefined;
};

export const useSocketFunctions = ({
  currPerspectiveWebsocket,
  selected,
  source,
  userId,
  accessCode,
}: SocketHookProps) => {
  const socket: any = useMemo(
    () => io(`${process.env.REACT_APP_SOCKET_URL}`, {query: {selected}}),
    [],
  );

  const socketSubscription = (
    channel: string,
    onDataUpdate: (newUserCount: string) => void,
  ) => {
    socket.on('userCountUpdate', (data: string) => {
      const parsedData = JSON.parse(data);
      parsedData.perspectiveId === selected &&
        onDataUpdate(parsedData.userCount);
    });
  };

  const sendUserData = () => {
    socket.emit('setUserData', {userId, accessCode, source});
  };

  const switchPerspectives = () => {
    socket.emit('decrement', currPerspectiveWebsocket.current);
    socket.emit('increment', selected);
    socket.emit('updateCurrent', selected);
    //we update the "old" perspective to the new perspective, as we will need to decrement it if we change perspectives again
    currPerspectiveWebsocket.current = selected;
  };
  return {socketSubscription, switchPerspectives, sendUserData, socket};
};
