import TagManager from 'react-gtm-module';
import {Route, Routes} from 'react-router-dom';
import AudienceWrapperHoC from './pages/Audience/Audience';
import Vod from './pages/Audience/Vod';
import {EnterCode} from './pages/EnterCode/EnterCode';

import {Outlet} from 'react-router-dom';
import {VodContextProvider} from './context/VodContext';

const VodContext = () => {
  return (
    <VodContextProvider>
      <Outlet />
    </VodContextProvider>
  );
};

function App() {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER!,
    events: {
      communityOpen: 'Community Open',
      communityClose: 'Community Close',
      externalLinkClick: 'Click to external link',
      feedbackClick: 'Feedback Button Click',
      addToCalendar: 'Save to Calendar Click',
    },
  };
  if (tagManagerArgs) {
    TagManager.initialize(tagManagerArgs);
  }
  return (
    <Routes>
      <Route path="/e/:accessCode" element={<AudienceWrapperHoC />} />
      <Route path="*" element={<EnterCode />} />
      <Route element={<VodContext />}>
        <Route path="/e/:accessCode/vod" element={<Vod />} />
      </Route>
    </Routes>
  );
}

export default App;
