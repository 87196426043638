import {FC} from 'react';
import {LinkButton} from '../../Buttons/LinkButton';
import {CommunityLinkProps} from './types';

export const CommunityLink: FC<CommunityLinkProps> = ({
  hasBottomBorder = false,
  favIconSource,
  favIconAlt,
  heading,
  url,
  styles,
  buttonStyles,
  label,
  textStyle,
}) => {
  return (
    <div className={`flex w-full justify-between gap-4 px-5 ${styles}`}>
      <img
        className="mt-3 h-12 w-12 rounded-[10px] object-cover"
        src={favIconSource}
        alt={favIconAlt}
      />
      <div
        className={`flex grow items-center justify-between py-5
          ${hasBottomBorder ? 'border-b-[0.5px] border-ic-grey-500' : ''}
        `}
      >
        <div className="flex flex-col">
          <h3 className="text-sm">{heading}</h3>
        </div>
        <LinkButton
          url={url}
          label={label}
          styles={buttonStyles}
          textStyle={textStyle}
        />
      </div>
    </div>
  );
};
