import {useState, useEffect} from 'react';
import {motion} from 'framer-motion';
import {CrowdEmojiReactionsProps, CrowdEmojiObject} from './types';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {getUserId} from '../../util/getUserId';

const CrowdEmojiReactions: React.FC<CrowdEmojiReactionsProps> = ({
  selected,
  socket,
  isOnPerspectiveTile,
}) => {
  const currentUserID = getUserId();
  const [animations, setAnimations] = useState<CrowdEmojiObject[]>([]);
  const isDesktopView = useWindowDimensions().width > 640;
  const rightEdge = !isDesktopView && !isOnPerspectiveTile ? 0 : 12;
  const leftEdge = isDesktopView ? 44 : 24;
  const yDisplacement = isOnPerspectiveTile ? -55 : -100;

  const listener = (emoji: string, perspectiveId: string, userID: string) => {
    if (userID !== currentUserID && perspectiveId === selected) {
      setAnimations(prevAnimations => {
        const randomXPos =
          Math.floor(Math.random() * (leftEdge - rightEdge + 1)) + rightEdge;
        const newEmojiObject = {
          emoji,
          animating: true,
          x: randomXPos,
        };
        return [...prevAnimations, newEmojiObject];
      });
    }
  };

  useEffect(() => {
    socket && socket.on('emoji_sent', listener);
    return () => {
      socket && socket.off('emoji_sent', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      animations.length > 0 &&
        animations.every((item: CrowdEmojiObject) => !item.animating) &&
        setAnimations([]);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [animations]);

  return (
    <div className="z-10">
      {animations.map(
        (item, index) =>
          item.animating && (
            <motion.div
              style={{right: item.x}}
              className="absolute bottom-8"
              key={index}
              initial={{y: 10, rotate: 0}}
              animate={{y: yDisplacement, rotate: [-10, 10, -10, 10, 0]}}
              transition={{duration: 0.7}}
              onAnimationComplete={() => {
                setAnimations(prevStates =>
                  prevStates.map((state, idx) =>
                    idx === index ? {...state, animating: false} : state,
                  ),
                );
              }}
            >
              {item.emoji}
            </motion.div>
          ),
      )}
    </div>
  );
};

export default CrowdEmojiReactions;
