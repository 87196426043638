import {validate, v4 as uuidv4} from 'uuid';

export function getUserId() {
  let userId = localStorage.getItem('anonUserId');
  if (!userId) {
    userId = uuidv4();
    localStorage.setItem('anonUserId', userId);
  }
  const validUserId = validate(userId);
  try {
    if (!validUserId) {
      throw new Error('Invalid UUID');
    }
    return userId;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}
