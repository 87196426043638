import cx from 'classnames';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {isIOS, isSafari} from 'react-device-detect';
import {ReactComponent as SpinLoaderIcon} from '../../assets/images/loader.svg';
import {useAudio} from '../../context/AudioContext';
import {useStreams} from '../../context/StreamsContext';
import {EventState, PerspectiveState} from '../../types/Event';
import {isPortrait} from '../../util/responsive';
import {SponsorLogo} from '../SponsorLogo/SponsorLogo';
import {Stream} from '../Stream/Stream';
import {StreamEnded} from '../StreamEnded/StreamEnded';
import styles from './StreamStack.module.scss';
import {StreamStackProps} from './types';

/**
 * A "stack" of synchronized stream players, where one stream is shown at a time.
 */
export const StreamStack: React.FC<StreamStackProps> = ({
  isFullScreenState,
  live,
  event,
  perspectives,
  selected,
  setSelected,
  mainVideo,
  mainAudio,
  setShowInitialMuteButton,
  currentStreamDimensions,
  communityTabShown,
  playing,
}) => {
  // We only need to recognize that we're muted from autoPlaying once.
  const [autoPlaying, setAutoPlaying] = useState(true);
  const {streams, recordChange} = useStreams();
  const {audioSource, setAudioSource, sourceMuted, setSourceMuted} = useAudio();
  const isStreamPortrait = isPortrait(
    currentStreamDimensions.videoHeight,
    currentStreamDimensions.videoWidth,
  );

  // We only use the main perspective as the audio/video if a main perspective is specified and it is live.
  const mainAudioLive =
    !!mainAudio &&
    perspectives[mainAudio].perspectiveState === PerspectiveState.Live;

  const mainVideoLive =
    !!mainVideo &&
    perspectives[mainVideo].perspectiveState === PerspectiveState.Live;

  // Checks if the current sound/video source is live
  const isAudioStreaming =
    !!audioSource &&
    perspectives[audioSource].perspectiveState === PerspectiveState.Live;

  const isVideoStreaming =
    !!selected &&
    perspectives[selected].perspectiveState === PerspectiveState.Live;

  // Check if the stream with the current audio/video source has been initialized.
  const isAudioInitialized =
    !!audioSource && !!streams.current[audioSource]?.pdt();
  const isVideoInitialized = !!selected && !!streams.current[selected]?.pdt();

  const hasNextPerspective = useMemo(
    () =>
      Object.values(perspectives).find(
        p => p.perspectiveState === PerspectiveState.Live,
      ) !== undefined,
    [perspectives],
  );

  // Detects if our current perspective went online/offline or just been initialized
  // Once the perspective is online and its stream has been initialized send the analytics.
  useEffect(() => {
    if (isAudioInitialized && isAudioStreaming)
      recordChange('set_audio', audioSource);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAudioInitialized, isAudioStreaming]);

  useEffect(() => {
    if (isVideoInitialized && isVideoStreaming)
      recordChange('set_video', selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVideoInitialized, isVideoStreaming]);

  // When the perspective ends, switch to the next available stream.
  const handleSwitchToNext = useCallback(
    (next: string) => {
      const ps = Object.values(perspectives);
      ps.sort((x, y) => x.order - y.order);
      const first = ps.find(p => p.perspectiveState === PerspectiveState.Live);
      if (first && live) {
        next === 'video' ? setSelected(first.id) : setAudioSource(first.id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [perspectives, setAudioSource, setSelected],
  );

  // If we have no selected stream, select one according to the following rules:
  // - Prefer the main perspective
  // - Select the first-ordered online stream otherwise
  // - don't select anything if event is not live
  useEffect(() => {
    if (!selected && live) {
      if (mainVideoLive) {
        setSelected(mainVideo);
      } else handleSwitchToNext('video');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, mainVideo, mainVideoLive, perspectives]);

  // Logic to control the active sound source
  // If a perspective that is NOT the current sound source joins/drops keep the current sound source
  // If the current sound source drops try to set the sound to mainAudio, otherwise find the first live perspective
  // If there are no live perspectives, set the sound source to be undefined
  // Miro board mapping the Context used here: https://miro.com/app/board/uXjVOiyselQ=/?share_link_id=525382542075
  useEffect(() => {
    if (live) {
      if (isAudioStreaming) return;
      else if (mainAudioLive) {
        setAudioSource(mainAudio);
      } else handleSwitchToNext('audio');
      if (isIOS && isSafari) {
        setSourceMuted(true);
        setShowInitialMuteButton(true);
      }
    } else {
      setAudioSource('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [live, perspectives, mainAudioLive]);

  // TODO: Interrupt a synchronization in progress.
  const synchronize = useCallback(() => {
    if (playing) {
      const syncStream = streams.current[audioSource];
      if (!syncStream) return;
      for (const [id, stream] of Object.entries(streams.current)) {
        if (!stream || (audioSource && id === audioSource)) continue;
        stream.synchronize(syncStream);
      }
    }
  }, [playing, streams, audioSource]);

  /*
   * Whenever a new stream is selected, we must synchronize all the existing
   * streams.
   */
  useEffect(synchronize, [synchronize, selected]);

  const streamStackRef = useRef<HTMLDivElement | null>(null);

  return (
    <div ref={streamStackRef} className="grid h-full w-full">
      <div className="z-0 col-start-1 row-start-1 grid place-content-center">
        {live && playing && (
          <SpinLoaderIcon className="h-10 w-10 animate-spin" />
        )}
      </div>
      {
        // Destroy players with not-live perspectives.
        Object.values(perspectives).map(p => (
          <div
            key={p.id}
            className={cx(
              selected === p.id && live ? 'block' : 'hidden',
              'z-10 col-start-1 row-start-1 max-h-full max-w-full overflow-hidden',
            )}
          >
            {p.perspectiveState === PerspectiveState.Live && (
              <Stream
                ref={el => {
                  streams.current[p.id] = el;
                }}
                isFullScreenState={isFullScreenState}
                eventTitle={event.title}
                event={event}
                perspective={p}
                muted={audioSource !== p.id || sourceMuted}
                // autoplay
                playing={playing}
                setMuted={m => {
                  if (selected === p.id && autoPlaying) {
                    setAutoPlaying(false);
                    setSourceMuted(m);
                  }
                }}
                reduceQuality={selected !== p.id}
                endStream={event.eventState === EventState.Ended}
              />
            )}
            {p.perspectiveState === PerspectiveState.Offline &&
              selected === p.id && (
                <StreamEnded
                  hasNext={hasNextPerspective}
                  onDone={handleSwitchToNext}
                />
              )}
          </div>
        ))
      }
      {event.eventState === EventState.Live && event.sponsorship && (
        <span
          id={styles.sponsorshipContainer}
          className={cx(
            isStreamPortrait ? 'bottom-2' : 'bottom-2 right-2',
            'absolute z-10 h-20 w-20 mp:h-10 mp:w-10',
            communityTabShown && isStreamPortrait && 'mr-80',
          )}
        >
          <SponsorLogo uri={event.sponsorship?.logo?.toString()} />
        </span>
      )}
    </div>
  );
};
