import cx from 'classnames';
import {
  useFlags,
  useLDClient,
  withLDProvider,
} from 'launchdarkly-react-client-sdk';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import TagManager from 'react-gtm-module';
import {useParams} from 'react-router-dom';
import {CommunityTab} from '../../components/CommunityTab/CommunityTab';
import {DesktopHeader} from '../../components/DesktopHeader/DesktopHeader';
import EventNotFound from '../../components/EventNotFound/EventNotFound';
import {Footer} from '../../components/Footer/Footer';
import {Loader} from '../../components/Loader/Loader';
import {MobileHeader} from '../../components/MobileHeader/MobileHeader';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {StreamViewer} from '../../components/StreamViewer/StreamViewer';
import {useDateTime} from '../../graphql/hooks/useDateTime';
import {useEvent} from '../../graphql/hooks/useEvent';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {EventState, Perspective} from '../../types/Event';
import {AudienceParams} from './types';
import {StreamViewerProps} from '../../components/StreamViewer/types';

const AudienceWrapperHoC = () => {
  const {accessCode} = useParams<keyof AudienceParams>() as AudienceParams; // Can't be undefined
  const Audience: React.FC = () => {
    const ld = useLDClient();
    const {isCommunityEnabled} = useFlags();
    const {event, error} = useEvent(accessCode);
    const {width, height} = useWindowDimensions();
    const [communityTabShown, setCommunityTabShown] = useState(false);

    //change this to update if state changes from live to not live
    const perspectiveMap: {[id: string]: Perspective} = useMemo(
      () =>
        event ? Object.fromEntries(event.perspectives.map(p => [p.id, p])) : {},
      [event],
    );
    const {startTime, timezone, title} = useDateTime(accessCode);
    const [timeElapsed, setTimeElapsed] = useState<number>(0);

    useEffect(() => {
      const interval = setInterval(() => {
        event?.eventState === EventState.Live
          ? setTimeElapsed(timeElapsed + 1000)
          : setTimeElapsed(0);
      }, 1000);
      return () => clearInterval(interval);
    }, [event?.eventState, timeElapsed]);

    const toggleCommunity = () => {
      if (!communityTabShown) {
        setCommunityTabShown(true);
        TagManager.dataLayer({
          dataLayer: {event: 'communityOpen'},
        });
      } else {
        setCommunityTabShown(false);
        TagManager.dataLayer({
          dataLayer: {event: 'communityClose'},
        });
      }
    };

    const isDimensionsLarge = width > 640 && height > 640;

    const initializeLDUser = useCallback(async () => {
      await ld?.identify({
        anonymous: true,
        custom: {eventCode: accessCode},
      });
    }, [accessCode, ld]);

    useEffect(() => {
      ld?.waitForInitialization()
        .then(() => {
          initializeLDUser();
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }, [initializeLDUser, ld]);

    useEffect(() => {
      if (event?.title) document.title = event.title;
    }, [event?.title]);

    useEffect(() => {
      setCommunityTabShown(isCommunityEnabled);
    }, [isCommunityEnabled]);

    const viewer = (viewerEvent: StreamViewerProps['event']) => {
      return (
        <div className={cx('h-full w-full')}>
          {title && startTime && timezone && (
            <StreamViewer
              isFullScreenState={isFullScreen}
              setIsFullScreenState={setIsFullScreen}
              event={viewerEvent}
              mainAudio={viewerEvent.mainAudio ?? null}
              mainVideo={viewerEvent.mainVideo ?? null}
              perspectives={perspectiveMap}
              isCommunityEnabled={isCommunityEnabled}
              communityTabShown={communityTabShown}
              communityTabItems={viewerEvent.communityTab}
              toggleCommunityTab={toggleCommunity}
              title={title}
              startTime={startTime}
              timezone={timezone}
            />
          )}
        </div>
      );
    };

    const [isFullScreen, setIsFullScreen] = useState(false);

    if (error) return <EventNotFound accessCode={accessCode} />;
    if (!event) return <Loader />;

    if (!isFullScreen)
      return (
        <div className="screen-height disable-screen-height-mb flex text-white">
          <div
            className={cx(
              'grid w-full grid-rows-layout gap-4 bg-black p-8 ml:grid-rows-1 ml:p-0 mp:block mp:p-0',
            )}
          >
            {isDimensionsLarge ? (
              <DesktopHeader
                titleProps={{
                  text: event.title,
                  isLive: event.eventState === EventState.Live,
                }}
                event={event}
              />
            ) : (
              <MobileHeader />
            )}
            <div className={cx('flex gap-4 ml:block mp:block')}>
              {viewer(event)}
              {!communityTabShown && isCommunityEnabled && (
                <div
                  className={cx(
                    'relative z-20 translate-y-[-6rem] self-center ml:hidden mp:hidden',
                  )}
                >
                  <Sidebar handleClick={toggleCommunity} />
                </div>
              )}
            </div>
          </div>
          {isDimensionsLarge && communityTabShown ? (
            <CommunityTab
              onClose={toggleCommunity}
              communityTabItems={event.communityTab}
            />
          ) : (
            <Footer />
          )}
        </div>
      );
    else
      return (
        <div className="screen-height disable-screen-height-mb flex text-white">
          <div className={cx('grid w-full grid-rows-layout ml:block mp:block')}>
            <header className="mb-3 flex select-none items-center justify-between"></header>
            <div>{viewer(event)}</div>
          </div>
        </div>
      );
  };

  const AudienceLDProvider = withLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_SDK_CLIENT!,
    context: {
      kind: 'user',
      anonymous: true,
      key: 'eventCode',
    },
    flags: {
      isCommunityEnabled: false,
      isCustomEvent: false,
    },
  })(Audience);

  return <AudienceLDProvider />;
};

export const useCustomEvent = (): boolean => useFlags().isCustomEvent;

export default AudienceWrapperHoC;
