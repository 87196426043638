import cx from 'classnames';
import {MouseEventHandler, useCallback} from 'react';
import {IconButtonProps} from './types';

export const IconButton: React.FC<IconButtonProps> = ({
  type = 'primary',
  size,
  icon,
  title,
  onClick,
  submit,
}) => {
  const onClickPreventDefault: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => onClick && onClick(), [onClick]);

  return (
    <button
      title={title}
      type={submit ? 'submit' : 'button'}
      className={cx(
        'flex',
        'justify-center',
        'items-center',
        type !== 'bare' ? ['rounded-full', 'p-2', 'active:ring'] : false,
        {
          primary: ['bg-ic-blue-400'],
          secondary: ['hover:bg-white/10'],
          bare: [],
        }[type],
        'hover:brightness-150',
      )}
      onClick={onClickPreventDefault}
      style={{width: size, height: size}}
    >
      <img src={icon} alt={title} />
    </button>
  );
};
