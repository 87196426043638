import {EventSponsorship} from './types';

export const MobileSponsorHeader: React.FC<{
  sponsorship?: EventSponsorship;
}> = ({sponsorship}) => {
  const title = sponsorship?.title;
  const logo = sponsorship?.logo;
  return title && logo ? (
    <div className="grow px-3 py-1">
      <div className="flex items-center">
        <img
          className="mr-2 h-9 w-9 content-center rounded-full"
          src={logo}
          alt="sponsor logo"
        />
        <div>
          <p className="mb-[-2px] text-xs text-gray-500">SPONSORED BY</p>
          <p className="text-base">{title}</p>
        </div>
      </div>
    </div>
  ) : null;
};
