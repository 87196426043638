import {createContext, useContext, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useEvent} from '../graphql/hooks/useEvent';
import {AudienceParams} from '../pages/Audience/types';
import {PerspectiveState} from '../types/Event';
import {
  MILLISECONDS_TO_SECONDS,
  StreamDimensionsState,
  StreamingVideo,
  VodEvent,
  VodPerspectiveContext,
  VodPlayback,
  PlaybackState,
  SkipType,
  VodContextI,
} from './types';
import isEqual from 'fast-deep-equal';

export const useVod = () => useContext(VodContext);

export const VodContext = createContext<VodContextI>({} as VodContextI);

export const VodContextProvider: React.FC<{children?: any}> = ({children}) => {
  const {accessCode} = useParams<keyof AudienceParams>() as AudienceParams;

  const {event, error, loading} = useEvent(accessCode);

  const vodPlaylist = useRef([] as VodPlayback[]);

  const [vodEvent, setVodEvent] = useState<VodEvent>({} as VodEvent);

  const [newTime, setNewTime] = useState(0);

  const [currentPlayingVideos, setCurrentPlayingVideos] = useState<
    StreamingVideo[]
  >([]);

  const [currentStreamDimensions, setCurrentStreamDimensions] =
    useState<StreamDimensionsState>({videoHeight: 0, videoWidth: 0});

  const elapsedTimeRef = useRef(0);
  const intervalRef: any = useRef(null);

  const updatePerspectives = (timeElapsed: number) => {
    if (event?.perspectives && event.perspectives.length > 0) {
      const vodPerspectives = event.perspectives.filter(
        p => p.vodPlaybacks && p.vodPlaybacks.length > 0,
      );
      const currentlyLivePlaybacks: VodPlayback[] = vodPlaylist.current.filter(
        v => {
          return (
            v.delayToStart <= timeElapsed &&
            v.delayToStart + v.videoDuration >= timeElapsed
          );
        },
      );

      const updatedPerspectives = Object.fromEntries(
        vodPerspectives.map(p => {
          const a = currentlyLivePlaybacks.find(
            vPb => vPb.perspectiveId === p.id,
          );

          return [
            p.id,
            {
              ...p,
              perspectiveState:
                currentlyLivePlaybacks.find(
                  vPb => vPb.perspectiveId === p.id,
                ) !== undefined
                  ? PerspectiveState.Live
                  : PerspectiveState.Offline,
              currentPlaybackId: a?.perspectiveId === p.id ? a.id : '',
            },
          ];
        }),
      );
      if (!isEqual(updatedPerspectives, vodEvent.perspectives)) {
        setVodEvent(prevVodState => {
          return {
            ...prevVodState,
            perspectives: updatedPerspectives,
          };
        });
      }
    }
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      elapsedTimeRef.current += 1;
      updatePerspectives(elapsedTimeRef.current);
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, [vodEvent]);

  useEffect(() => {
    if (event?.perspectives && event.perspectives.length > 0) {
      const vodPerspectives = event.perspectives.filter(
        p => p.vodPlaybacks && p.vodPlaybacks.length > 0,
      );
      const orderedVodPerspectives = vodPerspectives
        .reduce((acc, current) => {
          const {vodPlaybacks} = current;
          if (vodPlaybacks && vodPlaybacks.length > 0) {
            vodPlaybacks.forEach(v => {
              acc.push({
                id: v.id,
                liveStartTime: Number(v.startedAt),
                videoDuration: Number(v.videoLength) / MILLISECONDS_TO_SECONDS,
                perspectiveId: current.id,
                playbackState: PlaybackState.NOT_STARTED,
                delayToStart: 0,
              });
            });
          }
          return acc;
        }, [] as VodPlayback[])
        .filter(
          (obj, index, self) => index === self.findIndex(o => o.id === obj.id),
        )
        .sort((x, y): any => x.liveStartTime - y.liveStartTime);

      const assignFirstPlayback = orderedVodPerspectives.map(
        (v, i, playlist) => {
          return {
            ...v,
            ...(i === 0 && {playbackState: PlaybackState.STARTED}),
            ...(i !== 0 && {
              delayToStart:
                (v.liveStartTime - playlist[0].liveStartTime) /
                MILLISECONDS_TO_SECONDS,
            }),
          };
        },
      );

      const removeDeadAirTimeGap = assignFirstPlayback.map((v, i, playlist) => {
        const previousElements = playlist.slice(0, Math.max(i, 0));

        const isThereGap =
          previousElements.length > 0 &&
          previousElements.every(p => {
            const videoEnd = p.delayToStart + p.videoDuration;
            return v.delayToStart > videoEnd;
          });
        return {
          ...v,
          ...(isThereGap && {
            delayToStart:
              playlist[i - 1].delayToStart + playlist[i - 1].videoDuration,
          }),
        };
      });

      vodPlaylist.current = removeDeadAirTimeGap;
      const initialPerspectivesState: VodPerspectiveContext =
        Object.fromEntries(
          vodPerspectives.map((p, index) => [
            p.id,
            {
              ...p,
              perspectiveState:
                vodPlaylist.current[0].perspectiveId === p.id
                  ? PerspectiveState.Live
                  : PerspectiveState.Offline,
              currentPlaybackId:
                vodPlaylist.current[0].perspectiveId === p.id
                  ? vodPlaylist.current[0].id
                  : '',
              delayToStart: vodPlaylist.current[index].delayToStart,
              // currentTime: 0,
            },
          ]),
        );
      const lastVideoStartTime =
        vodPlaylist.current[vodPlaylist.current.length - 1].liveStartTime;

      const lastVideoDuration =
        vodPlaylist.current[vodPlaylist.current.length - 1].videoDuration;

      const eventEndTime = lastVideoStartTime / 1000 + lastVideoDuration;

      setVodEvent({
        perspectives: initialPerspectivesState,
        masterPlaylist: vodPlaylist.current,
        eventTitle: event.title,
        eventState: event.eventState,
        eventMainAudio: event.mainAudio,
        eventMainVideo: event.mainVideo,
        eventEndTime,
      });
    }
  }, [event]);

  const skipEventTime = (skipType: SkipType): void => {
    const skipTime = 15;
    if (skipType === 'forward') {
      elapsedTimeRef.current += skipTime;
    } else if (elapsedTimeRef.current > skipTime) {
      elapsedTimeRef.current -= skipTime;
    } else {
      elapsedTimeRef.current = 0;
    }
  };
  const setStreamingDimensions = (selectedPerspective: string): void => {
    const currentSelected = vodEvent.perspectives[selectedPerspective];
    if (currentSelected.perspectiveState === PerspectiveState.Live) {
      const currentPlayer = currentPlayingVideos.find(
        player => player.playbackId === currentSelected.currentPlaybackId,
      );
      if (!currentPlayer) {
        setCurrentStreamDimensions({
          videoHeight: 0,
          videoWidth: 0,
        });
        return;
      }
      currentPlayer?.video.addEventListener('loadeddata', () => {
        const {videoHeight, videoWidth} = currentPlayer.video;
        setCurrentStreamDimensions({
          videoHeight,
          videoWidth,
        });
      });
      try {
        if (currentPlayer?.video.videoWidth !== 0) {
        }
      } catch {
        return;
      }
      if (currentPlayer?.video.videoWidth !== 0) {
        setCurrentStreamDimensions({
          videoHeight: currentPlayer?.video.videoHeight,
          videoWidth: currentPlayer?.video.videoWidth,
        });
      }
    }
  };

  const seekTime = (time: number) => {
    elapsedTimeRef.current = time;
    setNewTime(time);
  };

  const getEventDurationTime = () => {
    const firstVideoStartTime = vodPlaylist.current[0].liveStartTime;
    return vodEvent.eventEndTime - firstVideoStartTime / 1000;
  };

  const value = {
    error,
    accessCode,
    loading,
    vodEvent,
    currentPlayingVideos,
    elapsedTime: elapsedTimeRef.current,
    setCurrentPlayingVideos,
    currentStreamDimensions,
    setCurrentStreamDimensions,
    setStreamingDimensions,
    updatePerspectives,
    rewindEvent: () => skipEventTime('rewind'),
    forwardEvent: () => skipEventTime('forward'),
    getEventEndTime: () => getEventDurationTime(),
    seekTime: (time: number) => seekTime(time),
    newTime,
    getCurrentTime: () => elapsedTimeRef.current,
  };

  return <VodContext.Provider value={value}>{children}</VodContext.Provider>;
};
