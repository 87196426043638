import {forwardRef, useEffect, useState} from 'react';
import {useInitPlayer} from '../../hooks/stream/useInitPlayer';
import {useSelectQuality} from '../../hooks/stream/useSelectQuality';
import {setPlayerSource} from '../../util/mux';
import Player from '../Player/Player';
import {VodStreamProps} from './types';
import {StreamRef} from '../../hooks/stream/types';
import {StreamingVideo} from '../../context/types';
import {useVod} from '../../context/VodContext';

export const VodStream = forwardRef<StreamRef, VodStreamProps>(
  (
    {
      eventTitle,
      perspectiveTitle,
      muted,
      setMuted,
      playing,
      autoplay,
      reduceQuality,
      perspectiveId,
    },
    stream,
  ) => {
    const {
      vodEvent,
      elapsedTime,
      setCurrentPlayingVideos,
      currentPlayingVideos,
      newTime,
    } = useVod();
    const playbackId =
      vodEvent && vodEvent.perspectives[perspectiveId].currentPlaybackId;

    const {player, playerRef} = useInitPlayer({
      eventTitle,
      perspectiveTitle,
      playbackId,
    });

    const [autoPlaying, setAutoPlaying] = useState(autoplay ?? false);

    // Select the lowest quality stream if "reduceQuality" is selected.
    useSelectQuality(player?.videoTracks[0], reduceQuality ?? false);

    // If we are auto playing muted, we need to propagate this information up.
    useEffect(() => {
      if (player && setMuted) {
        const listener = () => setMuted(player.muted);
        player.addEventListener('playing', listener);
        return () => player.removeEventListener('playing', listener);
      }
    }, [player, setMuted]);

    // Set the source when the stream URL changes/is set for the first time.
    useEffect(() => {
      if (player && playerRef.current) {
        const currentPlayingVid = vodEvent.masterPlaylist.filter(
          x => x.perspectiveId === perspectiveId,
        );
        if (
          currentPlayingVideos.some(
            (item: StreamingVideo) => item.playbackId === playbackId,
          )
        ) {
          const filteredPlayers = currentPlayingVideos.filter(
            (item: any) => item.playbackId !== playbackId,
          );
          setCurrentPlayingVideos(filteredPlayers);
        }

        setCurrentPlayingVideos((prevState: StreamingVideo[]) => [
          ...prevState,
          {video: player, playbackId},
        ]);

        player.source = setPlayerSource(playbackId);
        const currentDelayToStart = currentPlayingVid.filter(
          x => x.id === playbackId,
        )[0].delayToStart;

        player.currentTime = elapsedTime - currentDelayToStart;
        player.play();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [player, newTime]);

    // Call variable on/off methods when the associated prop changes.
    if (player) {
      player.autoplay = autoplay ?? false;
      player.muted = muted ?? false;

      if (playing && !autoPlaying) {
        player.play();
      }
      if (!playing) {
        player.pause();
      }
      player.volume = 1;
    }

    useEffect(() => {
      if (!playing) {
        setAutoPlaying(false);
      }
    }, [playing]);

    return (
      <Player
        perspectiveTitle={perspectiveTitle}
        stream={stream}
        player={player}
        playerRef={playerRef}
      />
    );
  },
);
