import utilStyles from '../../util/util.module.scss';
import styles from '../PerspectiveTile/PerspectiveTile.module.scss';

export const PlayingNextThumbnail = () => {
  return (
    <div
      className={`grid h-10 w-40 items-center overflow-hidden rounded bg-white/25 text-sm
        ${utilStyles.safariClip}`}
    >
      <div
        className={`${styles.timerBar}
          z-0 col-start-1 row-start-1 h-full bg-ic-blue-500`}
      />
      <div className="z-10 col-start-1 row-start-1 p-2">Playing next…</div>
    </div>
  );
};
