import {ReactComponent as SidebarIcon} from '../../assets/images/sidebar-icon.svg';
import cx from 'classnames';
import {SideBarItem} from './SideBarItem';

export const Sidebar = ({handleClick}: {handleClick: () => void}) => {
  return (
    <div
      className={cx('absolute right-0 z-10 rounded-lg bg-ic-blue-400')}
      data-testid="sidebar"
    >
      <SideBarItem handleClick={handleClick} Icon={SidebarIcon} />
    </div>
  );
};
