import {RefObject, useLayoutEffect, useState} from 'react';

export interface Dimensions {
  width: number;
  height: number;
}

// Listens for resizes and gives us an up-to-date
// REF: https://stackoverflow.com/questions/58483197/measure-react-dom-node-on-resize-with-hooks#answer-65303282
// REF: https://stackoverflow.com/questions/6492683/how-to-detect-divs-dimension-changed#answer-39312522
export function useElementDimensions<T extends Element>(
  ref: RefObject<T>,
): Dimensions | null {
  const [rect, setRect] = useState<Dimensions | null>(null);

  useLayoutEffect(() => {
    const current = ref.current;
    if (!current) {
      return;
    }

    const measure = () => {
      setRect({
        width: current.clientWidth,
        height: current.clientHeight,
      });
    };
    measure();

    let observer: ResizeObserver;
    observer = new ResizeObserver(measure);
    observer.observe(current);

    return () => observer.unobserve(current);
  }, [ref, setRect]);

  return rect;
}
