import cx from 'classnames';
import {useMemo} from 'react';
import {VodPerspectiveContext} from '../../context/types';
import {PerspectiveState} from '../../types/Event';
import utilStyles from '../../util/util.module.scss';
import {VodPerspectiveTile} from '../VodPerspectiveTile/VodPerspectiveTile';

export interface VodPerspectiveRadioProps {
  perspectives: VodPerspectiveContext;
  selected: string | null;
  onSelect: (id: string) => void;
}

export const VodPerspectiveRadio: React.FC<VodPerspectiveRadioProps> = ({
  perspectives,
  selected,
  onSelect,
}) => {
  const hasNextPerspective = useMemo(
    () =>
      Object.values(perspectives).find(
        p => p.perspectiveState === PerspectiveState.Live,
      ) !== undefined,
    [perspectives],
  );

  const tiles: [number, JSX.Element][] = Object.entries(perspectives)
    .filter(id => id[1].perspectiveState === PerspectiveState.Live)
    .map(([id, p]) => [
      p.order,
      <VodPerspectiveTile
        key={id}
        perspective={p}
        hasNextPerspective={hasNextPerspective}
        selected={selected === id}
        onClick={() => onSelect(id)}
      />,
    ]);
  tiles.sort((x, y) => x[0] - y[0]);

  return (
    <div
      className={cx(
        'mx-auto mt-7 flex h-fit max-w-full items-start justify-start gap-7 overflow-x-auto px-6 ml:p-3 mp:mt-0 mp:block mp:p-3',
        utilStyles.scrollbar,
      )}
    >
      {tiles.map(x => x[1])}
    </div>
  );
};
