import cx from 'classnames';
import {PerspectiveSponsorTitleProps} from './types';

export const PerspectiveSponsorTitle: React.FC<
  PerspectiveSponsorTitleProps
> = ({perspectiveSponsorshipTitle, styles}) => {
  return (
    <h3
      className={cx(`${styles}`)}
    >{`Sponsored By ${perspectiveSponsorshipTitle}`}</h3>
  );
};
