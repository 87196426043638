import {createContext, useContext, useState} from 'react';
import {PerspectiveContextI} from './types';

export const usePerspectives = () => useContext(PerspectiveContext);

export const PerspectiveContext = createContext<PerspectiveContextI>(
  {} as PerspectiveContextI,
);

export const PerpsectiveContextProvider: React.FC<{children?: any}> = ({
  children,
}) => {
  const [perspectiveId, setPerspectiveId] = useState<string | null>(null);

  const value = {perspectiveId, setPerspectiveId};

  return (
    <PerspectiveContext.Provider value={value}>
      {children}
    </PerspectiveContext.Provider>
  );
};
