import {Feedback} from '../Feedback/Feedback';
import {Title} from '../Title/Title';
import {DesktopHeaderProps} from './types';
import {SponsorHeader} from '../SponsorHeader/SponsorHeader';

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({
  titleProps,
  event,
  showToast = true,
}) => {
  return (
    <header className={'mb-3 flex select-none items-center justify-between'}>
      <Title text={titleProps.text} isLive={titleProps.isLive} />
      {event && (
        <SponsorHeader
          title={event.sponsorship?.title}
          logo={event.sponsorship?.logo}
        />
      )}
      <div className="flex space-x-5">
        <div className="mt-1">
          <Feedback title="Send Feedback" />
        </div>
      </div>
    </header>
  );
};
