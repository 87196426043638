import {FC} from 'react';
import cx from 'classnames';
import TagManager from 'react-gtm-module';

export interface LinkButtonProps {
  url: string;
  styles?: string;
  textStyle?: string;
  label: string;
}

export const LinkButton: FC<LinkButtonProps> = ({
  url,
  styles,
  textStyle = 'text-white py-1',
  label,
}) => {
  const sendAnalytics = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'externalLinkClick',
        externalUrl: url,
      },
    });
  };
  return (
    <a
      className={cx('rounded-full text-center', styles, textStyle)}
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={sendAnalytics}
    >
      {label}
    </a>
  );
};
