import {useEffect} from 'react';
import {UseInterval} from './types';

//sets up interval to run every 1s for timer and checking time difference between now and event start time
function useInterval({callback, duration}: UseInterval) {
  useEffect(() => {
    let id = setInterval(callback, duration);
    return () => clearInterval(id);
  }, [callback, duration]);
}

export default useInterval;
