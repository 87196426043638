import {useEffect, useRef} from 'react';

// Call the function every `ms` milliseconds.  If `ms` is null, stop the
// interval.
export const useInterval = (ms: number | null, callback: () => void): void => {
  const ref = useRef<() => void>();
  useEffect(() => (ref.current = callback), [callback]);

  useEffect(() => {
    if (!ms) return;

    const id = setInterval(() => ref.current && ref.current(), ms);
    return () => clearInterval(id);
  }, [ms]);
};
