import {FC, PropsWithChildren} from 'react';
import {useMediaQuery} from 'react-responsive';

const mobileLandscape = '(max-height: 640px) and (orientation: landscape)';
const desktop = '(min-height: 640px)';

export function useIsDesktop(): boolean {
  return useMediaQuery({query: desktop});
}

export function isPortrait(videoHeight: number, videoWidth: number) {
  return videoHeight > videoWidth;
}

export interface ResponsiveProps {
  invert?: boolean;
}

export const MobileLandscape: FC<PropsWithChildren<ResponsiveProps>> = ({
  children,
  invert,
}) => {
  const isMobileLandscape = useMediaQuery({query: mobileLandscape});
  return <>{isMobileLandscape !== (invert === true) ? children : null}</>;
};

interface CalculateStyle {
  measurementType: 'width' | 'height';
  isSmallScreen: boolean;
  width: number;
  height: number;
  aspectRatio: number;
}

interface CalculateStreamStyle extends CalculateStyle {
  currentStreamDimensions?: {
    videoWidth: number;
    videoHeight: number;
  };
}

export const calculateStreamStyle = ({
  measurementType,
  isSmallScreen,
  width,
  height,
  aspectRatio,
  currentStreamDimensions,
}: CalculateStreamStyle): 'unset' | number => {
  if (!isSmallScreen) return 'unset';

  const isLargeAspectRadio = width / height > aspectRatio;

  if (measurementType === 'height') {
    if (isLargeAspectRadio) return height;

    if (
      currentStreamDimensions &&
      currentStreamDimensions.videoWidth < currentStreamDimensions.videoHeight
    ) {
      return width;
    }
    return width * 0.5625;
  }

  return isLargeAspectRadio ? height / 0.5625 : width;
};
