import cx from 'classnames';
import {SponsorLogoProps} from './types';

export const SponsorLogo: React.FC<SponsorLogoProps> = ({uri, styles}) => {
  return (
    <img
      className={cx(`rounded-full object-cover ${styles}`)}
      alt=""
      src={uri?.toString()}
    />
  );
};
