import {FC} from 'react';
import {CardProps} from './types';

export const Card: FC<CardProps> = ({imgSource, altText, children}) => {
  return (
    <div
      className={
        'm-5 flex h-fit flex-col items-center overflow-hidden rounded-lg bg-ic-grey-700 drop-shadow'
      }
    >
      <img
        className="h-full w-full object-cover"
        src={imgSource}
        alt={altText}
      />
      {children}
    </div>
  );
};
