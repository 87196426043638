import {FullScreenButtonProps} from './types';
import logo from '../../assets/images/fullscreen.svg';
import closeLogo from '../../assets/images/minimise-svgrepo-com.svg';

export const FullScreenButton: React.FC<FullScreenButtonProps> = ({
  isFullScreenState,
  makeFullScreen,
}) => {
  return (
    <div>
      <button className="rounded p-5 font-bold" onClick={makeFullScreen}>
        {!isFullScreenState ? (
          <img
            src={logo}
            alt="Fullscreen"
            className="relative top-0.5 sm:w-[30px] md:w-[30px]"
          />
        ) : (
          <img
            src={closeLogo}
            alt="Exit Fullscreen"
            className="relative top-0.5 w-[23px]"
          />
        )}
      </button>
    </div>
  );
};
