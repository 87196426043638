import React, {useState} from 'react';
import cx from 'classnames';
import eventNotStartedBg from '../../assets/images/offline-bg.svg';
import {useInterval} from '../../hooks/useInterval';

export interface StreamEndedProps {
  // Is this for the stream, or the entire event?
  eventEnded?: boolean;
  // Is there another perspective to switch to?
  hasNext?: boolean;
  // Time is up, switch to next.
  onDone?: (next: string) => void;
}

export const StreamEnded: React.FC<StreamEndedProps> = ({
  eventEnded,
  hasNext,
  onDone,
}) => {
  // Time remaining until we switch perspectives.
  const [time, setTime] = useState(5);
  useInterval(time > 0 && hasNext ? 1000 : null, () => {
    setTime(time - 1);
    if (time <= 1 && onDone) {
      onDone('video');
    }
  });

  return (
    <div
      className="absolute inset-0 flex h-full w-full flex-col items-center justify-center bg-black bg-cover bg-center"
      style={{backgroundImage: `url(${eventNotStartedBg})`}}
    >
      <h2 className="whitespace-nowrap text-[7vw] sm:text-6xl">
        This {eventEnded ? 'stream' : 'perspective'} has ended.
      </h2>
      <h3 className="mt-[4vw] text-[4vw] sm:mt-10 sm:text-xl">
        {hasNext ? (
          <>
            Next available perspective playing in{' '}
            <span className={cx('text-ic-blue-500')}>{time}s</span>…
          </>
        ) : (
          <>Thanks for watching!</>
        )}
      </h3>
    </div>
  );
};
