import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {ReportHandler} from 'web-vitals';
import './index.css';
import client from './graphql/client';
import {ApolloProvider} from '@apollo/client';
import {AudioContextProvider} from './context/AudioContext';
import {StreamsContextProvider} from './context/StreamsContext';
import {PerpsectiveContextProvider} from './context/PerspectiveContext';

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <StreamsContextProvider>
        <AudioContextProvider>
          <PerpsectiveContextProvider>
            <App />
          </PerpsectiveContextProvider>
        </AudioContextProvider>
      </StreamsContextProvider>
    </BrowserRouter>
  </ApolloProvider>,

  document.getElementById('root'),
);

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({getCLS, getFID, getFCP, getLCP, getTTFB}) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
