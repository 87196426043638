function hlsUrl(playbackId: string): string {
  return `https://stream.mux.com/${playbackId}.m3u8`;
}

export function thumbnailUrl(
  playbackId: string,
  time: number | null = null,
  width: number = 270,
  height: number = 480,
): string {
  const params = new URLSearchParams({
    width: width.toString(),
    height: height.toString(),
  });
  if (time !== null) params.append('time', time.toString());
  return `https://image.mux.com/${playbackId}/thumbnail.jpg?${params}`;
}

export function setPlayerSource(playbackId: string) {
  return {
    sources: [
      {
        src: hlsUrl(playbackId),
        type: 'application/x-mpegurl',
        id: playbackId,
      },
    ],
  };
}
