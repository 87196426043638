import {useEffect, useRef, useState} from 'react';
import {ChromelessPlayer, UIPlayerConfiguration} from '@theoplayer/basic-hls';
import * as theoplayer from '@theoplayer/basic-hls';
import {THEOplayerMuxOptions} from '../../components/Stream/types';

interface UseInitPlayerInput {
  eventTitle: string;
  perspectiveTitle: string;
  playbackId?: string;
}

declare function initTHEOplayerMux(
  player: ChromelessPlayer,
  options: THEOplayerMuxOptions,
): void;

// theoplayer-mux expects theoplayer to exist on the default object.
declare global {
  interface Window {
    theoplayer: any;
  }
}
window.theoplayer = theoplayer;

export const useInitPlayer = ({
  eventTitle,
  perspectiveTitle,
  playbackId,
}: UseInitPlayerInput) => {
  const playerRef: any = useRef<HTMLDivElement | null>(null);
  const [player, setPlayer] = useState<ChromelessPlayer | null>(null);
  /*
   * Set up the theoplayer instance.  We call this once, at component mount,
   * and remember to return the destructor that cleans up the player when the
   * unmount.
   */
  useEffect(() => {
    if (!playerRef.current || !playbackId) {
      return;
    }
    const config: UIPlayerConfiguration = {
      license: process.env.REACT_APP_THEOPLAYER_LICENSE,
      libraryLocation: `${process.env.PUBLIC_URL}/theoplayer`,
      mutedAutoplay: 'all',
      ui: {fluid: true},
    };

    const p = new ChromelessPlayer(playerRef.current, config);
    setPlayer(p);

    initTHEOplayerMux(p, {
      debug: false,
      data: {
        env_key: process.env.REACT_APP_MUX_DATA_ENV ?? '',
        video_series: eventTitle,
        video_title: perspectiveTitle,
        video_id: playbackId,
        player_init_time: Date.now(),
      },
    });

    return () => {
      p && p.destroy();
    };
  }, [eventTitle, playbackId, perspectiveTitle]);

  return {player, playerRef};
};
