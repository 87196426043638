import React, {useState} from 'react';
import useInterval from '../../graphql/hooks/useInterval';
import {TimerProps} from './types';

export const Timer: React.FC<TimerProps> = ({targetTime, styles}) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const updateTime = () => {
    const updatedTimeRemaining = targetTime - Date.now();
    setTimeRemaining({
      days: Math.floor(updatedTimeRemaining / 86400000),
      hours: Math.floor((updatedTimeRemaining % 86400000) / 3600000),
      minutes: Math.floor((updatedTimeRemaining % 3600000) / 60000),
      seconds: Math.floor((updatedTimeRemaining % 60000) / 1000),
    });
  };

  //sets up timer to update time remaining before start of event every 1s
  useInterval({callback: updateTime, duration: 1000});

  return (
    <div>
      <p className={styles} data-testid="timer">
        {timeRemaining.days}d {timeRemaining.hours}h {timeRemaining.minutes}m{' '}
        {timeRemaining.seconds}s
      </p>
    </div>
  );
};
