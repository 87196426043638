import {useLazyQuery} from '@apollo/client';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ReactComponent as LockIcon} from '../../assets/images/lock.svg';
import {CheckEventDocument, EventState} from '../../generated/graphql';
import cx from 'classnames';
import styles from './EnterCode.module.scss';
import {usePerspectives} from '../../context/PerspectiveContext';

export const EnterCode: React.FC = () => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [code, setCode] = useState('');
  const [editable, setEditable] = useState(true);
  const urlParams = useParams();
  const {setPerspectiveId} = usePerspectives();
  const [checkEvent, {loading, error}] = useLazyQuery(CheckEventDocument, {
    onCompleted: ({getEvent: {accessCode, eventState}}) => {
      if (eventState === EventState.Ended) navigate(`/e/${accessCode}/Vod`);
      else navigate(`/e/${accessCode}`);
    },
    onError: () => {
      reset();
    },
  });
  const endsWithGuid = (inputString: string | undefined) => {
    // Define a regular expression pattern to match the expected format
    const pattern =
      /\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    // Use the test() method to check if the string matches the pattern
    return inputString && pattern.test(inputString);
  };

  useEffect(() => {
    const captureGuid =
      /.*\/(\d{6})\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/;
    urlParams['*'] && endsWithGuid(urlParams['*'])
      ? setPerspectiveId(urlParams['*'].replace(captureGuid, '$2'))
      : setPerspectiveId('');
  }, []);

  useEffect(() => {
    const captureAccessCode = /.*\/(\d{6})\/.*/;
    urlParams['*'] && submit(urlParams['*'].replace(captureAccessCode, '$1'));
  });

  const submit = useCallback(
    (accessCode: string) => {
      checkEvent({variables: {accessCode}});
    },
    [checkEvent],
  );

  const reset = useCallback(() => {
    setCode('');
    setEditable(true);
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [setCode, setEditable]);

  return (
    <div className="screen-height grid place-items-center bg-black text-white">
      <div className="flex max-w-[18rem] flex-col gap-4 rounded-md bg-ic-blue-300 p-4">
        <h1 className="text-xl">
          <LockIcon
            className={cx('mr-2 inline align-text-top', {
              [styles.error]: error,
              [styles.loading]: loading,
            })}
          />
          Enter an access code
        </h1>
        <input
          ref={inputRef}
          className="rounded bg-black py-2 pl-[0.5ch] font-monospace text-[7vw] outline-none placeholder:text-gray-800 focus:ring sm:text-6xl"
          autoFocus
          autoComplete="off"
          data-lpignore="true"
          placeholder="123456"
          pattern="[0-9]{6}"
          type="tel"
          value={code}
          disabled={!editable}
          onKeyPress={e => {
            if (code.length >= 6 || e.key < '0' || e.key > '9') {
              e.preventDefault();
            }
          }}
          onChange={({currentTarget: {value}}) => {
            if (value.length <= 6) {
              setCode(value);
            }
            if (value.length === 6) {
              submit(value);
              setEditable(false);
            }
          }}
        />
      </div>
    </div>
  );
};
