import {createContext, useCallback, useContext, useRef, useState} from 'react';
import {StreamRef} from '../hooks/stream/types';
import {sendAnalytics} from '../util/sendAnalytics';
import {StreamDimensionsState, StreamingVideo, StreamsContextI} from './types';
export const useStreams = () => useContext(StreamsContext);

// Use react context to thread the reference to our array of theoplayer handles
// through the entire component subtree, since it should not go in redux or
// apollo state management.
export const StreamsContext = createContext<StreamsContextI>(
  {} as StreamsContextI,
);

export const StreamsContextProvider: React.FC<{children?: any}> = ({
  children,
}) => {
  const [currentPlayingVideos, setCurrentPlayingVideos] = useState<
    StreamingVideo[]
  >([]);

  const [currentStreamDimensions, setCurrentStreamDimensions] =
    useState<StreamDimensionsState>({videoHeight: 0, videoWidth: 0});

  const streams = useRef<{[id: string]: StreamRef | null}>({});

  const recordChange = useCallback(
    (description: string, id: string) => {
      const data = {
        description,
        perspective: streams.current[id]?.title,
        playbackTime: streams.current[id]?.pdt()?.toISOString(),
      };
      sendAnalytics(data);
    },
    [streams],
  );

  const getPlaybackTime = (id: string) => {
    return streams.current[id]?.pdt()?.toISOString();
  };

  const value = {
    streams,
    recordChange,
    currentPlayingVideos,
    setCurrentPlayingVideos,
    currentStreamDimensions,
    setCurrentStreamDimensions,
    getPlaybackTime,
  };

  return (
    <StreamsContext.Provider value={value}>{children}</StreamsContext.Provider>
  );
};
