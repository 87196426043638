import MusicNotesLeftGif from '../../assets/images/incrowd-music-left.gif';
import MusicNotesRightGif from '../../assets/images/incrowd-music-right.gif';

export const MusicNotes: React.FC = () => {
  return (
    <>
      <img
        className={
          'absolute z-30 translate-x-[-115%] scale-90 mix-blend-screen mp:translate-x-[-100%] mp:scale-75'
        }
        src={MusicNotesLeftGif}
        alt="Music Notes"
      />
      <img
        className={
          'absolute z-30 translate-x-[115%] scale-90 mix-blend-screen mp:translate-x-[100%] mp:scale-75'
        }
        src={MusicNotesRightGif}
        alt="Music Notes"
      />
    </>
  );
};
