import {FSCProps} from './types';
import {FullScreenButton} from './FullScreenButton';

export const FullScreenControl: React.FC<FSCProps> = ({
  isFullScreenState,
  setIsFullScreenState,
}) => {
  return (
    <FullScreenButton
      isFullScreenState={isFullScreenState}
      makeFullScreen={() => setIsFullScreenState(!isFullScreenState)}
    />
  );
};

export default FullScreenControl;
