import {FC} from 'react';
import {TabButtonProps} from './types';

export const TabButton: FC<TabButtonProps> = ({
  label,
  styles,
  onClick,
  children,
}) => {
  return (
    <button
      className={`m-2 flex h-[32px] grow items-center justify-center rounded-full
        border-b-2 border-solid border-transparent
        ${styles}`}
      onClick={onClick}
    >
      {children}
      <span className={'whitespace-nowrap text-sm'}>{label}</span>
    </button>
  );
};
