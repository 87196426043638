import {Timer} from './Timer';
import {TimerContainerProps} from './types';

export const TimerContainer: React.FC<TimerContainerProps> = ({
  startTime,
  isLargeScreen,
}) => {
  const countdownTimer = startTime.local().format('MMM D, YYYY hh:mm A');
  const eventTimeInSeconds = startTime.unix() * 1000;

  return (
    <div className={'flex h-full items-center'} data-testid="timer-container">
      <div className={'m-auto'}>
        <Timer
          targetTime={eventTimeInSeconds}
          styles={`text-center ${
            isLargeScreen ? 'text-6xl mb-8' : 'text-4xl mb-4'
          }`}
        />
        <p
          className={`
            'text-center',
            ${isLargeScreen ? 'mt-8 text-2xl' : 'mt-4 text-sm'}
          `}
        >
          Premiering on {countdownTimer}
        </p>
      </div>
    </div>
  );
};
