import {useEffect} from 'react';

export const useSelectQuality = (
  videoTrack0: THEOplayer.MediaTrack | undefined,
  reduceQuality: boolean,
) => {
  useEffect(() => {
    if (videoTrack0) {
      if (!reduceQuality) {
        videoTrack0.targetQuality = undefined;
        return;
      }

      // Select the quality with the lowest bandwidth.
      const qualities = videoTrack0.qualities;
      if (qualities.length < 1) {
        videoTrack0.targetQuality = undefined;
        return;
      }
      let lowestQuality = qualities[0];
      for (let i = 1; i < qualities.length; i++) {
        if (qualities[i].bandwidth < lowestQuality.bandwidth) {
          lowestQuality = qualities[i];
        }
      }
      videoTrack0.targetQuality = lowestQuality;
    }
  }, [reduceQuality, videoTrack0]);
};
