import React from 'react';
import {BadgeProps} from './types';

export const Badge: React.FC<BadgeProps> = ({type = 'primary', children}) => (
  <div
    className={`flex h-6 select-none items-center rounded-full p-1 px-3 font-monospace text-xs
      ${type === 'primary' ? 'bg-ic-blue-500' : ''}
      ${type === 'secondary' ? 'border border-white' : ''}
    `}
  >
    {children}
  </div>
);
