import {useCallback, useEffect, useState, useRef} from 'react';
import cx from 'classnames';
import * as portals from 'react-reverse-portal';
import {useAudio} from '../../context/AudioContext';
import {EventState, PerspectiveState} from '../../types/Event';
import {isPerspectiveSponsorship} from '../../util/sponsorship';
import utilStyles from '../../util/util.module.scss';
import {SoundButton} from '../Buttons/SoundButton/SoundButton';
import styles from './PerspectiveTile.module.scss';
import {PerspectiveTileProps} from './types';
import {useVideoPlayerDimensions} from '../../hooks/useVideoPlayerDimensions';
import {SelectedThumbnail} from '../ThumbNails/SelectedThumbnail';
import {OfflineThumbnail} from '../ThumbNails/OfflineThumbnail';
import {PlayingNextThumbnail} from '../ThumbNails/PlayingNextThumbnail';
import {SponsorLogo} from '../SponsorLogo/SponsorLogo';
import {PerspectiveSponsorTitle} from '../SponsorTitle/PerspectiveSponsorTitle';
import CrowdEmojiReactions from '../EmojiReaction/CrowdEmojiReactions';

export const PerspectiveTile: React.FC<PerspectiveTileProps> = ({
  streams,
  currentPlayingVideos,
  recordChange,
  eventState,
  perspective,
  selected,
  hasNextPerspective,
  eventSponsorship,
  onClick,
  socket,
}) => {
  const {audioSource, setAudioSource, sourceMuted, setSourceMuted} = useAudio();
  const {tileDimensions, getTileDimensions} = useVideoPlayerDimensions(
    perspective,
    currentPlayingVideos,
  );
  const prevWidth = useRef(240);
  const prevHeight = useRef(480);

  useEffect(() => {
    if (tileDimensions.videoHeight)
      prevHeight.current = tileDimensions.videoHeight;
    if (tileDimensions.videoWidth)
      prevWidth.current = tileDimensions.videoWidth;
  }, [tileDimensions.videoHeight, tileDimensions.videoWidth]);

  const [variant, setVariant] = useState(0);
  useEffect(() => setVariant(Math.floor(Math.random() * 4)), [selected]);

  useEffect(() => {
    getTileDimensions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perspective, currentPlayingVideos]);

  const live =
    perspective.perspectiveState === PerspectiveState.Live &&
    eventState === EventState.Live;
  const selectable = !selected && live;

  const handleSound = () => {
    if (audioSource !== perspective.id) {
      setAudioSource(perspective.id);
      setSourceMuted(false);
      recordChange('switched_audio', perspective.id);
    } else {
      setSourceMuted(muted => !muted);
    }
  };

  const onClickCallback = useCallback(() => {
    if (selectable) {
      onClick();
    }
  }, [selectable, onClick]);

  const [thumbnailNode, setThumbnailNode] =
    useState<portals.HtmlPortalNode | null>(null);
  const liveThumbnail = thumbnailNode && (
    <portals.OutPortal onClick={onClickCallback} node={thumbnailNode} />
  );

  useEffect(() => {
    if (live && !selected) {
      const s = streams.current[perspective.id];
      setThumbnailNode(s?.thumbnailify() ?? null);
      return () => s?.unthumbnailify();
    }
  }, [live, selected, thumbnailNode, perspective.id, streams]);

  let renderedThumbnail: JSX.Element;

  switch (true) {
    case live && selected:
      renderedThumbnail = <SelectedThumbnail />;
      break;
    case live:
      renderedThumbnail = liveThumbnail || <></>;
      break;
    case eventState !== EventState.Live:
      renderedThumbnail = <OfflineThumbnail />;
      break;
    case selected && hasNextPerspective:
      renderedThumbnail = <PlayingNextThumbnail />;
      break;
    default:
      renderedThumbnail = <OfflineThumbnail />;
      break;
  }

  return (
    <div className="flex-none items-start justify-start mp:mb-4 mp:flex">
      <button
        onClick={onClickCallback}
        data-testid={`select-perspective-${perspective.id}`}
        className="relative w-56 pb-[56.25%] ml:w-[22vw] mp:min-w-[50%] mp:max-w-[50%] mp:pb-[28.125%]"
      >
        {!selected && (
          <div className="absolute bottom-[-12px] right-[-4px] z-50">
            <CrowdEmojiReactions
              selected={perspective.id}
              socket={socket}
              isOnPerspectiveTile={true}
            />
          </div>
        )}
        {isPerspectiveSponsorship(eventSponsorship, perspective) && (
          <span className="absolute bottom-2 left-2 z-10 h-8 w-8 mp:h-8 mp:w-8">
            <SponsorLogo
              uri={perspective?.sponsorship?.logo?.toString()}
              styles={'h-full w-full'}
            />
          </span>
        )}
        <div
          className={cx(
            'bg-[#01031a]',
            '!absolute top-0 bottom-0 right-0 left-0',
            'grid h-full w-full place-items-center rounded',
            {'active:ring': selectable},
            {'hover:brightness-150': selectable},
            selected && live && [styles.selected, styles[`selected${variant}`]],
            tileDimensions &&
              prevWidth.current < prevHeight.current &&
              !selected &&
              'm-auto w-1/4 items-center',
            utilStyles.safariClip,
            !live && !(selected && hasNextPerspective) && [styles.offline],
          )}
        >
          {renderedThumbnail}
        </div>
      </button>
      <div
        className={
          'mt-2 flex flex-auto items-start justify-between mp:mt-0 mp:items-center mp:self-center'
        }
      >
        <span className="pr-2 md:max-w-[180px] mp:ml-4">
          <h3 className="text-sm text-white">{perspective.title}</h3>
          {isPerspectiveSponsorship(eventSponsorship, perspective) && (
            <PerspectiveSponsorTitle
              styles={'text-sm text-gray-400'}
              perspectiveSponsorshipTitle={perspective.sponsorship?.title}
            />
          )}
        </span>
        {live && perspective.perspectiveState === PerspectiveState.Live && (
          <SoundButton
            handleSound={handleSound}
            sourceMuted={sourceMuted}
            audioSource={audioSource}
            perspectiveId={perspective.id}
          />
        )}
        {(eventState === EventState.Ended ||
          eventState === EventState.NotStarted) && (
          <div className="w-10 min-w-[40px]"></div>
        )}
      </div>
    </div>
  );
};
