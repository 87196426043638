import React from 'react';
import TagManager from 'react-gtm-module';

export interface FeedbackProps {
  title: string;
  className?: string;
}

export const Feedback: React.FC<FeedbackProps> = ({title, className}) => {
  return (
    <div>
      <a
        href={process.env.REACT_APP_FEEDBACK_TYPEFORM}
        target="_blank"
        className={`flex h-fit items-baseline gap-2 rounded-full border px-3 py-1 text-sm ${
          className ?? ''
        }`}
        rel="noreferrer"
        onClick={() => {
          TagManager.dataLayer({
            dataLayer: {event: 'feedbackClick'},
          });
        }}
      >
        {title}
      </a>
    </div>
  );
};
