import {SponsorLogo} from './SponsorLogo';
import {LeftSponsorLogoProps} from './types';

export const LeftSponsorLogo: React.FC<LeftSponsorLogoProps> = ({
  hasEventSponsorship,
  perspectiveSponsorship,
  perspectiveTitle,
  live,
}) => {
  return (
    <div className={'absolute top-0 left-0 z-20 mx-5 w-full mp:hidden'}>
      {!hasEventSponsorship && perspectiveSponsorship && live ? (
        <span className="flex items-center">
          <SponsorLogo
            styles="h-10 w-10 mr-2"
            uri={perspectiveSponsorship?.logo}
          />
          {perspectiveSponsorship.title
            ? `${perspectiveTitle} | Sponsored by ${perspectiveSponsorship?.title}`
            : perspectiveTitle}
        </span>
      ) : (
        <>
          {live && (
            <span className={'flex h-10 items-center'}>{perspectiveTitle}</span>
          )}
        </>
      )}
    </div>
  );
};
