import {ReactComponent as CommunityIcon} from '../../assets/images/link-icon.svg';
import {ReactComponent as PerspectivesIcon} from '../../assets/images/perspectives-icon.svg';
import {EventState} from '../../types/Event';
import {TabButton} from '../Buttons/TabButton/TabButton';
import styles from './Tab.module.scss';
import {TabProps} from './types';
import {MobileSponsorHeader} from '../SponsorHeader/MobileSponsorHeader';
import {Title} from '../Title/Title';

export const Tab: React.FC<TabProps> = ({
  isCustomEvent,
  eventState,
  eventTitle,
  selectedTab,
  isCommunityEnabled,
  onClickOnPerspectivesTab,
  onClickOnCommunityTab,
  eventSponsorship,
  socket,
  isVod,
  startTime,
  perspectives,
  currentPerspective,
}) => {
  return (
    <div
      className={`${
        isCustomEvent && 'mb-10'
      } z-0 hidden ml:mb-2 ml:!mt-0 ml:block mp:mb-2 mp:block`}
    >
      <div className={'max-h-[100px] grow space-y-1 overflow-hidden p-3'}>
        <Title
          text={eventTitle}
          isLive={eventState === EventState.Live}
          socket={socket}
          isVod={isVod}
          startTime={startTime}
          perspectives={perspectives}
          currentPerspective={currentPerspective}
        />
      </div>
      {eventSponsorship && (
        <MobileSponsorHeader sponsorship={eventSponsorship} />
      )}
      <div className={'flex items-center justify-evenly'}>
        {isCommunityEnabled && (
          <TabButton
            label="Perspectives"
            onClick={onClickOnPerspectivesTab}
            styles={
              selectedTab === 'Perspectives' ? styles.active : styles.inactive
            }
          >
            <PerspectivesIcon className={'mr-2 w-4'} />
          </TabButton>
        )}
        {isCommunityEnabled && (
          <TabButton
            label="Community"
            onClick={onClickOnCommunityTab}
            styles={
              selectedTab === 'Community' ? styles.active : styles.inactive
            }
          >
            <CommunityIcon className={'mr-2 w-4'} />
          </TabButton>
        )}
      </div>
    </div>
  );
};
