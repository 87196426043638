import closeIcon from '../../assets/images/close.svg';
import {IconButton} from '../Buttons/IconButton/IconButton';
import {CommunityLink} from './CommunityLink/CommunityLink';
import {CommunityCard} from './CommunityCard/CommunityCard';
import utilStyles from '../../util/util.module.scss';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {CommunityTabProps, CommunityTabItem} from './types';

export const CommunityTab: React.FC<CommunityTabProps> = ({
  onClose,
  communityTabItems,
}) => {
  const {communityItems} = communityTabItems;
  const {width, height} = useWindowDimensions();
  const isDimensionsLarge: boolean = width > 640 && height > 640;
  const containsAllFields = (item: CommunityTabItem): boolean | string => {
    const {iconImageUrl, iconAlt, heading, link, buttonLabel} = item;
    return iconImageUrl && iconAlt && heading && link && buttonLabel;
  };
  return (
    <div
      className={`h-screen w-1/3 overflow-auto rounded ml:w-full mp:w-full
        ${isDimensionsLarge ? 'min-w-[350px]' : ''}
        to-ic-blue-400' bg-gradient-to-b from-ic-blue-200
        drop-shadow mp:h-fit mp:bg-none
        ${utilStyles.scrollbar}`}
    >
      <div
        className={
          'flex items-center rounded-t border-b border-ic-blue-700 bg-ic-blue-400 py-2 px-3 ml:hidden mp:hidden'
        }
      >
        <h2 className="grow">Community</h2>
        <IconButton
          icon={closeIcon}
          size="1rem"
          title="Close Info"
          type="bare"
          onClick={onClose}
        />
      </div>
      {communityItems.map((item: CommunityTabItem) => (
        <>
          {containsAllFields(item) &&
            (item.graphicImageUrl && item.graphicAlt ? (
              <CommunityCard
                favIconSource={item.iconImageUrl}
                favIconAlt={item.iconAlt}
                imgSource={item.graphicImageUrl}
                altText={item.graphicAlt}
                heading={item.heading}
                url={item.link}
                label={item.buttonLabel}
                buttonStyles="bg-ic-grey-500 w-28 h-8"
              />
            ) : (
              <CommunityLink
                hasBottomBorder={true}
                favIconSource={item.iconImageUrl}
                favIconAlt={item.iconAlt}
                heading={item.heading}
                url={item.link}
                label={item.buttonLabel}
                buttonStyles="bg-ic-grey-500 w-28 h-8"
              />
            ))}
        </>
      ))}
    </div>
  );
};
