import {ApolloError} from '@apollo/client';
import {ChromelessPlayer} from '@theoplayer/basic-hls';
import {Dispatch, MutableRefObject, SetStateAction} from 'react';
import {StreamRef} from '../hooks/stream/types';
import {EventState, Perspective, PerspectiveState} from '../types/Event';

export interface StreamingVideo {
  video: ChromelessPlayer;
  playbackId: string;
}

interface StreamDimensions {
  videoHeight: number | undefined;
  videoWidth: number | undefined;
  currentPlayingVideos: StreamingVideo[];
  setCurrentPlayingVideos: Dispatch<SetStateAction<StreamingVideo[]>>;
  currentStreamDimensions: any;
  setCurrentStreamDimensions: Dispatch<SetStateAction<any>>;
}

export interface StreamDimensionsState
  extends Pick<StreamDimensions, 'videoHeight' | 'videoWidth'> {}

type StreamDimensionsHandler = Omit<
  StreamDimensions,
  'videoHeight' | 'videoWidth'
>;

export interface PerspectiveContextI {
  perspectiveId: string | null;
  setPerspectiveId: Dispatch<SetStateAction<string | null>>;
}

export interface StreamsContextI extends StreamDimensionsHandler {
  streams: MutableRefObject<{[id: string]: StreamRef | null}>;
  recordChange: (description: string, id: string) => void;
  getPlaybackTime: (id: string) => string | undefined;
}

export interface AudioContextI {
  audioSource: string;
  setAudioSource: Dispatch<SetStateAction<string>>;
  sourceMuted: boolean;
  setSourceMuted: Dispatch<SetStateAction<boolean>>;
  toggleMute: () => void;
}

export interface VodPerspective extends Perspective {
  currentPlaybackId: string;
  currentTime?: number;
}

export interface VodPerspectiveContext {
  [id: string]: VodPerspective;
}

export interface VodPlayback {
  id: string;
  liveStartTime: number;
  videoDuration: number;
  perspectiveId: string;
  playbackState: PlaybackState;
  delayToStart: number;
  currentPlaybackTime?: number;
}

export interface VodEvent {
  masterPlaylist: VodPlayback[];
  perspectives: VodPerspectiveContext;
  eventTitle: string;
  eventState: EventState;
  eventMainAudio?: string | null;
  eventMainVideo?: string | null;
  skip?: boolean;
  eventEndTime: number;
}

export interface VodContextI extends StreamDimensionsHandler {
  error: ApolloError | undefined;
  accessCode: string;
  loading: boolean;
  vodEvent: VodEvent;
  setStreamingDimensions: (selectedPerspective: string) => void;
  rewindEvent: () => void;
  forwardEvent: () => void;
  getEventEndTime: () => number;
  seekTime: (time: number) => void;
  getCurrentTime: () => number;
  elapsedTime: number;
  newTime: number;
  updatePerspectives: (elapsedTime: number) => void;
}

export interface UpdatePerspectivesInput {
  perspectiveId: string;
  perspectives: VodPerspectiveContext;
  perspectiveState: PerspectiveState;
  newPlaybackId?: string;
  nextCurrentTime?: number;
}

export interface UpdateVodEventInput {
  newMasterVideo: VodPlayback;
  newMasterPlaylist: VodPlayback[];
  newPerspectives: VodPerspectiveContext;
}

export interface GetNextLivePerspectiveInput {
  currentPerspectiveId: string;
  currentVodEvent: VodEvent;
}

export interface UpdatePlaylistInput {
  playlist: VodPlayback[];
  nextPlaybackId: string;
  state?: PlaybackState;
}

export interface EndPlaybackInput {
  currentPerspectiveId: string;
  currentPlaybackId: string;
}

export interface SetNextLivePerspectiveInput {
  currentVodEvent: VodEvent;
  currentPerspectiveId: string;
  nextPerspectiveId: string;
}

export const MILLISECONDS_TO_SECONDS = 1000;

export enum PlaybackState {
  'NOT_STARTED',
  'STARTED',
  'ENDED',
}

export const SKIP_TIME = 10;

export type SkipType = 'rewind' | 'forward';
