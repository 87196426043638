import * as portals from 'react-reverse-portal';
import {PlayerProps} from './types';
import {useManageStream} from '../../hooks/stream/useManageStream';

/* 
  InPortal renders the stream as normal but only displays when using portals.Outportal.
  The main stream (!thumbnailed) is displayed from this component, 
  while the smaller streams are displayed from perspectiveTile.tsx
*/
const Player: React.FC<PlayerProps> = ({
  stream,
  player,
  perspectiveTitle,
  playerRef,
}) => {
  const {portalNode, thumbnailed} = useManageStream(
    stream,
    player,
    perspectiveTitle,
  );
  return (
    <>
      <portals.InPortal node={portalNode}>
        <div
          ref={playerRef}
          className="relative h-full w-full"
          data-testid="player"
        />
      </portals.InPortal>
      {!thumbnailed && <portals.OutPortal node={portalNode} />}
    </>
  );
};

export default Player;
