import {ReactComponent as SoundMutedIcon} from '../../../assets/images/mute.svg';
import SoundPlayingGif from '../../../assets/images/soundPlaying.gif';
import {SoundButtonProps} from './types';

export const SoundButton: React.FC<SoundButtonProps> = ({
  handleSound,
  sourceMuted,
  audioSource,
  perspectiveId,
}) => {
  return (
    <button onClick={handleSound} className={'hover:brightness-150'}>
      {sourceMuted || audioSource !== perspectiveId ? (
        <SoundMutedIcon
          className={
            'h-10 w-10 rounded-full bg-ic-grey-600 fill-ic-grey-400 p-2'
          }
          data-testid="sound-muted-icon"
        />
      ) : (
        <img
          className={'h-10 w-10 min-w-[40px] rounded-full bg-ic-blue-500 p-2'}
          src={SoundPlayingGif}
          alt="Perspective Playing Sound"
        />
      )}
    </button>
  );
};
