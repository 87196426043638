import cx from 'classnames';
import {ReactComponent as MutedIcon} from '../../assets/images/mute.svg';

export const MutedIconContainer: React.FC = () => {
  return (
    <>
      <MutedIcon
        className={cx(
          'mr-2 hover:brightness-75 active:brightness-50 mp:h-5 mp:w-5',
        )}
      />
      <h3 className={cx('invisible text-white mp:visible mp:text-sm')}>
        Tap to unmute
      </h3>
    </>
  );
};
